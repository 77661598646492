import React from "react";
import { withTranslation } from "react-i18next";
import {
	Tab,
	Table,
	Form,
	Button,
	Icon, 
} from "semantic-ui-react";  
import axios from "axios"; 
 
class RollerConcept extends React.Component {  
    
    constructor(props) {
		super(props);  

		console.log('props', props, 'consolelog RollerConcept props'); 

		const [jobKey] = this.props.edit.state.copyOrigins;
		const projectID = props.edit.state.pid;
		let selectedJob;
		if(jobKey) {
			selectedJob = this.props.data.props.app.projects.getJobs([projectID + '#' + jobKey]); 
		}
		const formDataRollerData =  props.data.state.formData?.roller_data;  		
		let PassessInitial, RollerInitial; 
		if(formDataRollerData?.rollerPasses){
			PassessInitial = formDataRollerData?.rollerPasses;
		}
		if(formDataRollerData?.rollers?.length){
			RollerInitial = [...formDataRollerData?.rollers];
		}
		let rollerDataState;
		const jobs = props.data.state.jobs; 		
		if(jobs.size > 1){			
			const jobsA = [...jobs];
			const [processId, jobId] = jobsA[0].split("#"); 
			rollerDataState = this.props.edit.getRollerData(processId, jobId); 
			if(rollerDataState?.rollerPasses){
				PassessInitial = rollerDataState?.rollerPasses;
			}
			if(rollerDataState?.rollers?.length){
				RollerInitial = [...rollerDataState?.rollers];
			}			
		} else if (jobKey){
			rollerDataState = selectedJob[0].roller_data; 

			if(rollerDataState?.rollerPasses){
				PassessInitial = rollerDataState?.rollerPasses;
			}
			if(rollerDataState?.rollers?.length){
				RollerInitial = [...rollerDataState?.rollers];
			}	
		}


		this.state = {
			projectID: props.edit.state.pid,
            rollingPerformanceNeed: "",
            rollingPerformanceChosen: 0,
			rollingPerformanceRest: "", 
			showRSPRest: true,
			rollerPasses: PassessInitial ?? 1, 
			rollerRequestButtonDisable: false,
			rollerRequestSuccessMessage: false,
			rollerRequestFailureMessage: false,
			rollersData: [{
				rollerKey: RollerInitial?.length ? RollerInitial[0]?.rollerKey : Date.now(), 
				rollerBandage: RollerInitial?.length ? RollerInitial[0]?.rollerBandage : 0,
				rollerOverlap: RollerInitial?.length ? RollerInitial[0]?.rollerOverlap : 0,
				workProportion: RollerInitial?.length ? RollerInitial[0]?.workProportion : 50,
				rollerSpeed: RollerInitial?.length ? RollerInitial[0]?.rollerSpeed : 0,
				rollerAmount: RollerInitial?.length ? RollerInitial[0]?.rollerAmount : 1,
				rollerPaverNeeded: RollerInitial?.length ? RollerInitial[0]?.rollerPaverNeeded : 0,
				calulatedRoller: RollerInitial?.length ? RollerInitial[0]?.calulatedRoller : 0,
				rollingPerformanceFinal: RollerInitial?.length ? RollerInitial[0]?.rollingPerformanceFinal : 0,			
				rollingRequestID: RollerInitial?.length ? RollerInitial[0]?.rollingRequestID : ""			
			}]
        }
		let rollersData = this.state.rollersData; 		
		if(RollerInitial?.length > 1 ){
			const RollerInitialRollers = RollerInitial;
			RollerInitialRollers.slice(1).forEach(function(roller, index) {
				rollersData[index+1] = { 
					rollerKey: roller.rollerKey, 
					rollerBandage: roller.rollerBandage,
					rollerOverlap: roller.rollerOverlap,
					workProportion: roller.workProportion,
					rollerSpeed: roller.rollerSpeed,
					rollingPerformanceFinal: roller.rollingPerformanceFinal,
					rollingRequestID: roller.rollingRequestID,
					calulatedRoller: roller.calulatedRoller,
					rollerAmount: roller.rollerAmount,
					rollerPaverNeeded: 0
				}
			}); 			
		} 
		this.setState({ rollersData }); 
		this.handleRollerPerformanceData();
	}    

    componentWillMount(){ 
		//const formDataRollerData =  this.props.data.state.formData?.roller_data;
		//if(!formDataRollerData){
        	this.getData(); 
		//}
    }

    async getData() {  
        try {
			const response = await axios.get(
				process.env.REACT_APP_BPO_URL +
				"/common/interfaces/InfraProjectData.php?b=" + this.props.data.state.formData.bpo +
				"&action=get_paving" +
				"&v=" + process.env.REACT_APP_SERVER_VERSION,
				{
					headers: {
                        BPOAuth: "2d0a7a8f-106a-4ff6-91a6-c9760a7af2cc",
                    },
				}
			); 
			// Calculating 'Walzflächenleistung'
			if(response.data.status === 'success'){
				// const rollingPerformanceCalc = parseFloat(response.data.paving_widtheness)*parseFloat(response.data.paving_speed)*60;
				const rollingPerformanceCalc = response.data.roller_performance_needed;
				this.setState({  
					rollingPerformanceNeed: Math.round(rollingPerformanceCalc),
					//taktFilename: response.data.takt_filename,
					rollingPerformanceRest: 0 - Math.round(rollingPerformanceCalc),
				});
				this.handleRollerPerformanceData();
			}       
		} catch (error) {
			if (error.response && error.response.status === 401) {
				 
			}  
		}
    }	 

	handleChange(id, key, value){ 
		let rollersData = this.state.rollersData;   
		rollersData[id][key] = value; 
		this.setState({ rollersData });
	} 

	handleRollerPerformanceData() {  
		let rollersData = this.state.rollersData;
		let rollingPerformanceChosenTemp = 0;
		let rollingPerformanceRestTemp = 0;
		let rollerCounter = 0
		let calcVal = 0; 
		const marked = this.props.data.state.marked;
 
		const rollersArray = rollersData.map((roller) => {
			if( 
				roller.rollerBandage && 
				roller.rollerSpeed &&  
				(this.state.rollingPerformanceNeed || marked.size > 1) &&
				(this.state.rollerPasses || marked.size > 1)
			) {	 
				
				// calcVal = Math.round( ( ( parseFloat(roller['rollerBandage']) - (parseFloat(roller['rollerOverlap'])/100) ) * parseFloat(roller['rollerSpeed']) * 50 ) / parseFloat(this.state.rollerPasses) );
				calcVal = Math.round( ( ( parseFloat(roller['rollerBandage']) - (parseFloat(roller['rollerOverlap'])/100) ) * parseFloat(roller['rollerSpeed']) * parseFloat(roller['workProportion']) ) / parseFloat(this.state.rollerPasses) );
				roller['rollingPerformanceFinal'] = calcVal;
				let rollerAmountTemp = ( roller['rollerAmount'] )? roller['rollerAmount'] : 0
				rollingPerformanceChosenTemp = rollingPerformanceChosenTemp + ( calcVal * parseFloat(rollerAmountTemp) );
				rollingPerformanceRestTemp = this.state.rollingPerformanceNeed - rollingPerformanceChosenTemp
				this.setState({ 
					rollingPerformanceChosen: rollingPerformanceChosenTemp,
					rollingPerformanceRest: rollingPerformanceRestTemp
				})
				return roller
			} 
			roller['rollingPerformanceFinal'] = 0;
			roller['calulatedRoller'] = 0;
			return roller
			
		}) 

		if(marked.size === 1){
			let calcVal1 = 0;
			const rollersArray2 = rollersArray.map((roller) => {
				rollerCounter++;			
				if( 
					roller.rollingPerformanceFinal  
					//&& this.state.rollingPerformanceNeed 
					//&& this.state.rollingPerformanceRest 
				) {				
					if(rollerCounter > 1) { 
						calcVal1 = this.state.rollingPerformanceRest / roller.rollingPerformanceFinal;	
					}else { 
						calcVal1 = this.state.rollingPerformanceNeed / roller.rollingPerformanceFinal;	
					} 
					roller['calulatedRoller'] = Math.abs(calcVal1.toFixed(2)); 
					
					setTimeout(() => { 
						this.updateJobsToSave();						
					}, 200);

					return roller
				} else { 
					return roller
				} 
			});
			this.setState({  
				rollersArray2 
			});  
		} else if (marked.size > 1 ) {

			setTimeout(() => { 
				this.updateJobsToSave();				
			}, 200);
		}
	}

	handleAddRollerClick() {				
		this.setState(({
			rollersData: [...this.state.rollersData, {
				rollerKey: Date.now(), 
				rollerBandage: 0,
				rollerOverlap: 0,
				workProportion: 50,
				rollerSpeed: 0,
				rollerPasses: "",
				rollerAmount: 1,
				rollerPaverNeeded: 0,
				calulatedRoller: 0,
				rollingPerformanceFinal: 0,
				rollingRequestID: ""
			}]
		})) 
	}
 
	handleRemoveRollerClick(id) {
		let rollersData = this.state.rollersData;
		rollersData.splice(id, 1);
		this.setState({ rollersData });

		setTimeout(() => { 
			this.handleRollerPerformanceData()
		}, 100);
	}	


	handleRequestRoller() {  
		let rollersData = this.state.rollersData;  
		const projectID = this.state.projectID;
		let selectedJob;		
		const marked = this.props.data.state.marked; 
		let added = 0;
		marked.forEach((key) => {
			let operations = [];
			let selectedRequirements = {};
			let selectedJobs = [];
			selectedJob = this.props.data.props.app.projects.getJobs([projectID + '#' + key]); 
			selectedJobs.push(selectedJob[0]);
			const openRequests = selectedJob[0].openRequests;
			if(openRequests.length){
				openRequests.forEach((req) => { 					
					if(req._requirement._resclass.name?.toLowerCase() !== "walzen")					
						selectedRequirements[req._requirement.id] = {count: req.count, comment: req.comment};
				});
			}  
			rollersData.forEach((roller) => {
				if(roller.rollingRequestID){
					//let count = roller.rollerAmount;
					//if(selectedRequirements.hasOwnProperty(roller.rollingRequestID)){
						//count = selectedRequirements[roller.rollingRequestID].count + count;
					//}
					selectedRequirements[roller.rollingRequestID] = {count: roller.rollerAmount, comment: roller.rollerBandage+'m '+ this.props.t("edit.roller_concept.labels.bandage_width")+", "+roller.rollerSpeed + 'm/min '+this.props.t("edit.roller_concept.labels.performance_speed")};									
					// this.setState({
					// 	rollerRequestButtonDisable: true,
					// 	rollerRequestSuccessMessage: true			
					// });					 
					++added;				
				}  		
			}); 
			if(added === 0 ) {
				/* this.setState({
					rollerRequestButtonDisable: false,
					rollerRequestFailureMessage: true, 		
				});
				setTimeout( ()=> { 
					this.setState({
						rollerRequestFailureMessage: false		
					});
				}, 3000) */
				alert("Please select Roller group.");	

				return;
			}
			operations.push({jobs: selectedJobs, requests: selectedRequirements});
			this.props.data.props.app.projects.requestDispose(operations);
			this.props.data.props.app.ui.closeResourceEditor();
			alert("Request created successfully!");	
			return;
		}); 

	}

	updateJobsToSave() { 

		const formDataRollerData =  this.props.data.state.formData?.roller_data;
		const jobs = this.props.data.state.jobs; 
		const marked = this.props.data.state.marked;

		if(!formDataRollerData){
			this.props.data.state.formData.roller_data = { 
				rollerPasses: this.state.rollerPasses,
				rollers: [{}]	
			}
		} else { 
			this.props.data.state.formData.roller_data.rollerPasses = this.state.rollerPasses || 1
			this.props.data.state.formData.roller_data.rollers = [{}] 
		}  
		
		let rollersDataSave = this.state.rollersData;
		rollersDataSave.forEach((roller, index) => {
			this.props.data.state.formData.roller_data.rollers[index] = 	{ 
																				rollerKey: roller.rollerKey, 
																				rollerBandage: roller.rollerBandage,
																				rollerOverlap: roller.rollerOverlap,
																				workProportion: roller.workProportion,
																				rollerSpeed: roller.rollerSpeed,
																				rollingPerformanceFinal: roller.rollingPerformanceFinal,
																				rollingRequestID: roller.rollingRequestID,
																				calulatedRoller: roller.calulatedRoller,
																				rollerAmount: roller.rollerAmount,
																				rollerPaverNeeded: 0
																			}
		});


		this.props.data.state.formData.roller_data.bulk_action_jobs = []
		if(marked.size > 1){	 
			// eslint-disable-next-line		
			for (let [key, value] of jobs.entries()) {
				// eslint-disable-next-line		
				const [processId, jobId] = value.split("#"); 
				this.props.data.state.formData.roller_data.bulk_action_jobs.push(jobId);
			}
		}	 

		const rollerDataJSON = this.props.data.state.formData.roller_data; 

		console.log('rollerDataJSON', rollerDataJSON, 'consolelog rollerDataJSON');

		const updatedList = []; 		 

		this.props.edit.saveHistory();
		//this.props.data.changeValue("roller_data", rollerDataJSON)
		// eslint-disable-next-line		
		for (let [key, value] of jobs.entries()) {
			const [processId, jobId] = value.split("#"); 
			this.props.edit.setRollerdata(processId, jobId, rollerDataJSON);
			updatedList.push([processId, jobId, 0]); 						 
			continue;
		} 
			
		this.props.edit.move(updatedList); 		
	} 
 
    render(){  
		const marked = this.props.data.state.marked; 
		const walzenResources = [];
		this.props.data.props.app.resources.reqList().forEach((c) => {			
			if (c.resClass.name?.toLowerCase() === "walzen" ){
				c.res.forEach( r => walzenResources.push({text: r.name, value: r.id, key: r.id }) );
			} 
		})   
		
        return (
            <Tab.Pane>
                <React.Fragment>


					<Table singleLine>
						<Table.Body>
							<Table.Row>

								<Table.Cell> 
									<div className="roller_block_one_wrap">
										<div className="roller_block_one"> 
											{marked.size === 1?	
												<div className="roller_block_one_field"> 
													<div className="roller_block_one_field_label"> 
														{this.props.t("edit.roller_concept.labels.performance_paver")}:
													</div>
													<div className="roller_block_one_field_field"> 
														{this.state.rollingPerformanceNeed.toLocaleString('en-DE')} m<sup>2</sup>/h	
													</div>
												</div>
											: null }
											{marked.size === 1?	
												<div className="roller_block_one_field"> 
													<div className="roller_block_one_field_label"> 
														{this.props.t("edit.roller_concept.labels.performance_chosen")}:
													</div>
													<div className="roller_block_one_field_field"> 													 
														{this.state.rollingPerformanceChosen.toLocaleString('en-DE')} m<sup>2</sup>/h 													
													</div>
												</div>
											: null }
											{marked.size === 1?	
												<div className="roller_block_one_field"> 
													<div className="roller_block_one_field_label"> 
														{this.props.t("edit.roller_concept.labels.performance_rest")}:
													</div>
													<div className="roller_block_one_field_field"> 
														{this.state.rollingPerformanceRest.toLocaleString('en-DE')} m<sup>2</sup>/h
													</div>
												</div>
											: null }
												
											<div className="roller_block_one_field"> 
												<div className="roller_block_one_field_label"> 
													{this.props.t("edit.roller_concept.labels.passes")}:
												</div>
												<div className="roller_block_one_field_field "> 
													<Form.Field className='roller-concept-NSPN-input'> 
														<Form.Input   
															type="number"  
															min="0"
															value={this.state.rollerPasses ?? ""}
															onChange={(event: any) => { 
																this.setState({ rollerPasses: parseInt(event.target.value) })
																setTimeout( ()=> {
																	this.handleRollerPerformanceData()
																}, 100)																
															}}         
															/>
													</Form.Field>
												</div>
											</div> 

											<div className="roller_block_one_field"> 
												<div className="roller_block_one_button roller_add_button">
													<Button
														floated="left"
														icon 
														primary 
														size="large"
														onClick={() => this.handleAddRollerClick()}
													>
														<Icon name="plus" size="small"/>
													</Button>
												</div>
											</div>	
										</div>										
									</div>
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									<div className="roller_block_two_wrap">
										{this.state.rollersData.map((roller, index) => (
											<React.Fragment key={roller.rollerKey}>
												<div> 

													<Table singleLine className="NSPN-table">
														<Table.Header>
															<Table.Row>
																<Table.Cell>
																	<div className="roller_group_head">
																		<strong>{this.props.t("edit.roller_concept.labels.roller_group")}</strong>
																	</div>																
																</Table.Cell>

																{ Object.keys(walzenResources).length && Object.keys(walzenResources).length > index ?
																	
																	<Table.Cell>
																		<Form.Select
																		label=""
																		text={roller.rollingRequestID ? "" : "Select Roller"}
																		value={roller.rollingRequestID ?? ""}
																		onChange={(e, data) => {																				 
																			this.handleChange(index, 'rollingRequestID', data.value);
																			setTimeout( ()=> {
																					this.handleRollerPerformanceData(index);
																				}, 100);
																			}
																		}
																		options={walzenResources}
																		/>
																	</Table.Cell>																	
																: null }

																{ index ? 
																	<Table.Cell className="roller_remove_button">
																	
																		<Button
																			floated="right"  
																			basic="true" 
																			size="small"
																			color="#ffffff"
																			onClick={() => this.handleRemoveRollerClick(index)}
																		>
																			<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 30 30">
																				<path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
																			</svg>
																		</Button> 
																	
																	</Table.Cell>
																: null } 
															</Table.Row>
														</Table.Header>														
													</Table>

													<Table singleLine className="NSPN-table">																								
														<Table.Body>
															<Table.Row>
																<Table.Cell>
																	{this.props.t("edit.roller_concept.labels.bandage_width")}:
																</Table.Cell>
																<Table.Cell> 
																	<Form.Field className='roller-concept-NSPN-input'> 
																			<div className="field">
																				<div className="ui input">
																					<input 
																					placeholder="" 
																					type="number"
																					title="RollerBandage"
																					id="rollerBandage" 
																					name="rollerBandage"
																					defaultValue={roller.rollerBandage ?? ""}
																					min="0.00"
																					step="1" 
																					presicion={2}    
																					onChange={(e) => { 
																						const value = parseFloat(e.target.value) 
																						this.handleChange(index, 'rollerBandage', value); 
																						this.handleChange(index, 'rollerOverlap', parseFloat(((value*100)*10)/100)); 
																						setTimeout( ()=> {
																							this.handleRollerPerformanceData(index);
																						}, 100)				 
																					}}
																					/>
																				</div>
																			</div>

																			<div className="NSPN-unit">m</div>  
																	</Form.Field>												
																</Table.Cell>																													
															</Table.Row>

															<Table.Row>
																<Table.Cell>
																	{this.props.t("edit.roller_concept.labels.overlap")}:
																</Table.Cell>
																<Table.Cell> 
																	<Form.Field className='roller-concept-NSPN-input'> 																		 
																		<div className="field">
																			<div className="ui input">
																				<input 
																				placeholder="" 
																				type="number"
																				title="RollerOverlap"
																				id="rollerOverlap" 
																				name="rollerOverlap"
																				value={roller.rollerOverlap ?? 0}
																				min="0.00"
																				step="1" 
																				presicion={2}    
																				onChange={(e) => { 
																					const value = parseFloat(e.target.value)																			 
																					this.handleChange(index, 'rollerOverlap', value); 
																					setTimeout( ()=> {
																						this.handleRollerPerformanceData(index);
																					}, 100)				 
																				}}
																				/>
																			</div>
																		</div>
																		<div className="NSPN-unit">cm</div>  
																	</Form.Field>												
																</Table.Cell>																													
															</Table.Row>															

															<Table.Row>
																<Table.Cell>
																	{this.props.t("edit.roller_concept.labels.performance_speed")}:
																</Table.Cell>
																<Table.Cell> 
																	<Form.Field className='roller-concept-NSPN-input'> 
																		<div className="field">
																			<div className="ui input">
																				<input 
																				placeholder="" 
																				type="number"
																				title="rollerSpeed"
																				id="rollerSpeed" 
																				name="rollerSpeed"
																				defaultValue={roller.rollerSpeed ?? ""}
																				min="0.00"
																				step="1" 
																				presicion={2}    
																				onChange={(e) => { 
																					const value = parseFloat(e.target.value)																			 
																					this.handleChange(index, 'rollerSpeed', value);																					
																					setTimeout( ()=> {
																						this.handleRollerPerformanceData(index);
																					}, 100)						 
																				}}
																				/>
																			</div>
																		</div>
																		<div className="NSPN-unit">m/min</div>  
																	</Form.Field>												
																</Table.Cell>
															</Table.Row>

															<Table.Row>
																<Table.Cell>
																	{this.props.t("edit.roller_concept.labels.work_proportion")}:
																</Table.Cell>
																<Table.Cell> 
																	<Form.Field className='roller-concept-NSPN-input'> 																		 
																		<div className="field">
																			<div className="ui input">
																				<input 
																				placeholder="" 
																				type="number"
																				title="WorkProportion"
																				id="workProportion" 
																				name="workProportion"
																				value={roller.workProportion ?? 50}
																				min="0"
																				step="1" 
																				max="60"
																				presicion={2}    
																				onChange={(e) => { 
																					const value = parseFloat(e.target.value)																			 
																					this.handleChange(index, 'workProportion', value); 
																					setTimeout( ()=> {
																						this.handleRollerPerformanceData(index);
																					}, 100)				 
																				}}
																				onBlur={(e) => {
																						const value = parseFloat(e.target.value);
																						if(value > 60 ) {
																							this.handleChange(index, 'workProportion', 60); 
																						} else if (value <= 0 ) {
																							this.handleChange(index, 'workProportion', 50);	
																						}
																						setTimeout( ()=> {
																							this.handleRollerPerformanceData(index);
																						}, 100)	
																					}
																				} 
																				/>
																			</div>
																		</div>
																		<div className="NSPN-unit">m/h</div>  
																	</Form.Field>												
																</Table.Cell>																													
															</Table.Row>

															<Table.Row>
																<Table.Cell>
																	<div className="roller_performance_single_result">
																		{this.props.t("edit.roller_concept.labels.roller_performance")}: &nbsp;
																		<div className="roller_tooltip">
																			<Icon name="info" size="small" className="tooltip_icon"/>
																			<div className="roller_tooltip_top">
																				<h4>Walzflächenleistung</h4>
																				<div className="roller_tooltip_row">beff = b-u</div>
																				<div className="roller_tooltip_row">b = Bandagenbreite (m)</div>
																				<div className="roller_tooltip_row">u = Überlappung (cm)</div>
																				<div className="roller_tooltip_row">v = Walzgeschwindigkeit (m/min)</div>
																				<div className="roller_tooltip_row">n = Überfahrten</div>
																				<div className="roller_tooltip_row">f = Walzarbeit abzgl. Verteilzeit / Anteil des Walzens pro Stunde in Minuten (min)</div>																																						 
																				<div className="roller_tooltip_row roller_tooltip_formula">F = (beff*v*f)/n</div>  
																				<i></i>
																			</div>
																		</div>
																	</div>
																</Table.Cell>
																<Table.Cell>																
																	{roller.rollingPerformanceFinal.toLocaleString('en-DE')} m<sup>2</sup>/ h													
																</Table.Cell>
															</Table.Row>  

															<Table.Row>
																<Table.Cell>
																	<div className="roller_performance_single_result">
																		{this.props.t("edit.roller_concept.labels.roller_requirement")}:
																	</div>
																</Table.Cell>
																<Table.Cell>
																	{roller.calulatedRoller.toLocaleString('en-DE')} Stück 
																</Table.Cell>
															</Table.Row>
															<Table.Row>
																<Table.Cell>
																	{this.props.t("edit.roller_concept.labels.roller_Amount")}:
																</Table.Cell>
																<Table.Cell>
																	<Form.Field className='roller-concept-NSPN-input'> 
																		<Form.Input 
																			placeholder=""  
																			type="number" 
																			min="1" 
																			value={roller.rollerAmount}
																			name="rollerAmount"
																			onChange={(e) => { 
																				const value = parseInt(e.target.value)																		 
																				this.handleChange(index, 'rollerAmount', value);  
																				setTimeout( ()=> {
																					this.handleRollerPerformanceData(index);
																					this.setState({
																						rollerRequestButtonDisable: false		
																					});
																				}, 100)	
																			}}	
																			onBlur={(e) => {
																				const value = parseInt(e.target.value);
																				if (value <= 0 ) {
																					this.handleChange(index, 'rollerAmount', 1);	
																				}
																				setTimeout( ()=> {
																					this.handleRollerPerformanceData(index);
																				}, 100)	
																			}
																		} 		
																			/>
																			<div className="NSPN-unit">Stück</div> 																			
																	</Form.Field>
																</Table.Cell>
															</Table.Row>
														</Table.Body>
													</Table>
												</div>	
											</React.Fragment>
										))}

									</div>
								</Table.Cell>

							</Table.Row>

							<Table.Row>
								<Table.Cell>
									<div className="roller_request_button_wrap">
										<Button
											floated="left"										
											disabled={this.state.rollerRequestButtonDisable}
											icon 
											primary 
											size="large"
											// onClick={() => this.handleRequestRoller()}
											onClick={() => {
												if(window.confirm('Are you sure to request rollers?')){ 
													this.handleRequestRoller()
												};
											}}
										>										
											<div className="roller_tooltip roller_tooltip_request">
												<Icon name="arrow right" size="large"/>
												<div className="roller_tooltip_top">
													<h5>Request Rollers</h5> 
													<i></i>
												</div>
											</div>	
										</Button> 
										{ /* this.state.rollerRequestSuccessMessage ? 
											<div className="roller_request_success_message">Request created successfully!</div>
										: null} 										
										{this.state.rollerRequestFailureMessage ? 
											<div className="roller_request_failure_message">Please select Roller group.</div>
										: null */ }
									</div>


									


								</Table.Cell>
							</Table.Row>

							
						</Table.Body>
					</Table> 

                </React.Fragment>
            </Tab.Pane>
        );
    }   

};
 
export default withTranslation()(RollerConcept);