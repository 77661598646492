import React from "react";
import { observer, inject } from "mobx-react";
import { strToDate } from "../../../helpers/calendar";
import { Popup, Icon } from "semantic-ui-react";

class ProjectRequestLine extends React.Component {
	constructor(props) {
		super(props);
		this.state = {}; 
	}

	static getX(props, date) {
		return (
			((date.getTime() - props.app.ui.view.start.getTime()) /
				props.app.ui.calDuration) *
			props.xwidth
		);
	}

	static getDate(props, x) {
		return (props.app.ui.calDuration * x) / props.xwidth;
	}

	static getWidth(props, x) {
		return (x * props.xwidth) / props.app.ui.calDuration;
	}

	render() { 
		const width =
			((this.props.app.ui.view.end - this.props.app.ui.view.start) /
				this.props.app.ui.calDuration) *
			this.props.xwidth;

		const data = this.props.data;
		const boxes = [];

		const lineHeight = 30;
		const padding = 0;
		const boxHeight = lineHeight - 2 * padding;
		let projectId = "";	
		let requestComment;
		const requirmentId = data.requirement.id; 
		// eslint-disable-next-line
		for (let [day, requestDay] of Object.entries(data.days)) {
			if(requirmentId === requestDay._requirement.id && requestDay._requirement._resclass.name.toLowerCase() === "walzen") {
				requestComment = requestDay.comment;
				continue;
			}
		}	 

		const planningsPavingdata = this.props.planningsPavingdata; 

		for (let [personalId, request] of Object.entries(data.days)) {
			let start = ProjectRequestLine.getX(this.props, strToDate(personalId));
			let end = ProjectRequestLine.getX(this.props, strToDate(personalId, 1));
			let processes = [request.process.id];
			let isBeingMoved = this.props.pstate.dayMap.has(personalId);

			let pos = 0;
			let offset = 0;
 
			let rollerPerformancelabel;

			if (this.props.pstate.pid === "") {
			} else if (
				isBeingMoved &&
				(this.props.pstate.sub === "main" ||
					processes.includes(this.props.pstate.sub))
			) {
				offset = this.props.pstate.dayMap.get(personalId);
			}
			projectId = request.project.id;
			const marked =
				this.props.calendar.state.selected.includes(
					projectId + "#" + request.process.id + "#" + personalId
				) &&
				data.requirement.id === this.props.calendar.state.lastRequirement.id;

			const hovered =
				this.props.app.ui.mouseOver.day === personalId &&
				this.props.app.ui.mouseOver.resource === data.key;

			let rollerTotalClass = 'res_txt_green'; 
			
			if(data.requirement._resclass.name.toLowerCase() === "walzen" && this.props.app.ui.modules.has("ROLLER_CONCEPTS") && this.props.app.ui.rights.has("READ_ROLLER_MASTERDATA")) {
				if(planningsPavingdata[request.job?.bpo] !== undefined) {	
					const openRequests = request.job.openRequests;  
					let requestsCount = 1;
					const resourcesIDs = [];
					const resources = request.job.resources; 
					let roller_performance_total = 0;
					let rollerPerformanceNeeded = Math.round(planningsPavingdata[request.job?.bpo].roller_performance_need);
					requestsCount = openRequests.filter(x => x._requirement._resclass.name.toLowerCase() === "walzen").length;
					
					
					if(resources.size ){
						resources.forEach((res) => {  
							if(!resourcesIDs.includes(res.resource.id) && res.resource._resclass.name.toLowerCase() === "walzen" ){
								resourcesIDs.push(res.resource.id);
							}
						});
					}  
					if(request.job?.bpo && request.job?.roller_data?.roller_resources?.length){ 
						const rollerPassess = request.job?.roller_data?.rollerPasses || 1;
						const roller_resources = request.job?.roller_data?.roller_resources;
						roller_resources.forEach((res) => {
							if(resourcesIDs.includes(res.resource) ){
								roller_performance_total+= Math.round( (0.9*parseFloat(res.rollerBandage)*parseFloat(res.rollerSpeed)*50)/parseFloat(rollerPassess) ); 
							}
						}); 
					}
					const requestPerfromanceNeeded = Math.round( ( parseFloat(rollerPerformanceNeeded) - parseFloat(roller_performance_total) ) / requestsCount );
					if(roller_performance_total < rollerPerformanceNeeded) rollerTotalClass = 'res_txt_red';					 
					//rollerPerformancelabel = requestPerfromanceNeeded.toLocaleString('en-DE') + '/' + rollerPerformanceNeeded.toLocaleString('en-DE');					
					if(requestPerfromanceNeeded)
						rollerPerformancelabel = requestPerfromanceNeeded.toLocaleString('en-DE') + ' m<sup>2</sup>/ h';					
				}
			}



			boxes.push(
				<div
					key={personalId}
					className={
						(offset !== 0 || pos !== 0 ? "request_box moved" : "request_box") +
						(hovered ? " hovered" : "") +
						(marked ? " marked" : "")
					}
					onClick={
						(this.props.app.ui.modules.has("REQUEST_PLANNING") &&
							this.props.app.ui.rights.has("PLAN_REQUESTS")) ||
						this.props.app.ui.modules.has("PLAN_RESOURCES")
							? (e) =>
									this.props.calendar.handleResClick(
										e,
										request.project.id,
										request.process.id,
										personalId,
										data.requirement
									)
							: null
					}
					onMouseEnter={(e) =>
						this.props.app.ui.setMouseOver({
							projects: [],
							processes: processes,
							resource: data.key,
							day: personalId,
						})
					}
					onMouseLeave={(e) => this.props.app.ui.resetMouseOver()}
					style={{
						cursor: "pointer",
						position: "absolute",
						left: start + 1 + offset,
						width: end - start - 1,
						height: boxHeight - 1,
						top: padding,
					}}
				>
					{rollerPerformancelabel ? (
					<div className={"res_perf_data  " + rollerTotalClass } dangerouslySetInnerHTML={{ __html: rollerPerformancelabel }}></div>
					) : null}
					{request.comment.length ? ( 
						<Popup
							position='right center'
							content={request.comment}
							trigger={<Icon name="comment" className="requestPopupTrigger"/>}
						/>
					) : null}
				</div>
			);
		}

		return (
			<tr key={"tr" + data.key} className={"project_requirement"}>
				<td
					className="project_requirement_head sticky"
					style={{
						cursor: "pointer",
						left: 0,
						zIndex: 3,
						verticalAlign: "top",
					}}
				>
					<div
						className="resource_name"
						style={{
							whiteSpace: "nowrap",
							height: lineHeight,
						}}
					>
						<div
							className="imageInner"
							style={{
								backgroundImage: "url(" + data.requirement.imageUrl + ")",
							}}
						/>
						<div
							className="nameInner requestName"
							onClick={
								(this.props.app.ui.modules.has("REQUEST_PLANNING") &&
									this.props.app.ui.rights.has("PLAN_REQUESTS")) ||
								this.props.app.ui.modules.has("PLAN_RESOURCES")
									? (e) =>
											this.props.calendar.handleResClick(
												e,
												projectId,
												false,
												false,
												data.requirement
											)
									: null
							}
						>
							{data.requirement.name}
							{requestComment ? (
								<Popup
									position='right center'
									content={requestComment}
									trigger={<Icon name="comment" className="requestPopupTrigger"/>}
								/>
							) : null}
							
						</div>
					</div>
				</td>
				<td>
					<div
						className="project_body"
						style={{
							zIndex: 2,
							width: width,
							height: lineHeight,
							position: "relative",
						}}
					>
						{boxes}
					</div>
				</td>
			</tr>
		);
	}
}

export default inject("app")(observer(ProjectRequestLine));
