import React from "react";
import moment from "moment";
import FilterInput from "../controls/filterInput";
import { observer, inject } from "mobx-react";

class CalendarHeader extends React.Component {
	constructor(props) {
		super(props);
		this.firstColumn = React.createRef();
	}

	render() {
		const width =
			((this.props.view.end - this.props.view.start) /
				this.props.calDuration) *
			this.props.xwidth;
		//console.log(this.props.view, this.props.calDuration, this.props.xwidth);
		const viewstart = moment(this.props.view.start).startOf("day");
		const viewend = moment(this.props.view.end).startOf("day");
		const days = [];
		const weekdays = [];
		const weeks = [];
		const months = [];
		const monthBuilder = iterator => (x, j) => (
			<div key={"kmx" + i + "-" + j}>{iterator.format("MMMM YYYY")}</div>
		);
		let lastWeek = 0;
		let lastMonth = 0;
		let i = 0;
		const changeRight = this.props.app.ui.rights.has("SET_HOLIDAYS");
		for (
			let iterator = viewstart.clone();
			iterator <= viewend;
			iterator.add(1, "day")
		) {
			const working = this.props.app.basedata.checkWorkingDay(iterator,changeRight);

			let start = iterator.valueOf();
			let end = iterator
				.clone()
				.endOf("day")
				.valueOf();
			let dayleft =
				((start - this.props.view.start) / this.props.calDuration) *
				this.props.xwidth;
			let daywidth =
				((end - start) / this.props.calDuration) * this.props.xwidth;
			days.push(
				<div
					key={"kd" + i}
					className={
						working.state
							? "calheader_day"
							: "calheader_day holiday"
					}
					onClick={this.props.edit ? null : working.changeFn}
					style={{
						position: "absolute",
						left: dayleft,
						width: daywidth,
						height: 20,
						lineHeight: "20px",
						boxSizing: "border-box"
					}}
				>
					{iterator.format("D")}
				</div>
			);
			weekdays.push(
				<div
					key={"kt" + i}
					onClick={this.props.edit ? null : working.changeFn}
					className={
						working.state
							? "calheader_day"
							: "calheader_day holiday"
					}
					style={{
						position: "absolute",
						left: dayleft,
						width: daywidth,
						height: 20,
						lineHeight: "20px",
						boxSizing: "border-box"
					}}
				>
					{iterator.format("dd")}
				</div>
			);

			let weekstart = iterator
				.clone()
				.startOf("isoWeek")
				.valueOf();
			if (lastWeek !== weekstart) {
				let weekend = moment(weekstart)
					.endOf("isoWeek")
					.valueOf();
				let weekleft =
					((weekstart - this.props.view.start) /
						this.props.calDuration) *
					this.props.xwidth;
				let weekwidth =
					((weekend - weekstart) / this.props.calDuration) *
					this.props.xwidth;
				weeks.push(
					<div
						key={"kw" + i}
						className="calheader_day"
						style={{
							position: "absolute",
							left: weekleft,
							width: weekwidth,
							height: 20,
							lineHeight: "20px",
							boxSizing: "border-box"
						}}
					>
						{"KW " + iterator.format("W")}
					</div>
				);

				lastWeek = weekstart;
			}

			let monthstart = iterator
				.clone()
				.startOf("month")
				.valueOf();
			if (lastMonth !== monthstart) {
				let monthend = moment(monthstart)
					.endOf("month")
					.valueOf();

				let monthleft =
					((monthstart - this.props.view.start) /
						this.props.calDuration) *
					this.props.xwidth;
				let monthwidth =
					((monthend - monthstart) / this.props.calDuration) *
					this.props.xwidth;

				months.push(
					<div
						key={"km" + i}
						className="calheader_month"
						style={{
							height: 30,
							position: "absolute",
							left: monthleft,
							width: monthwidth,
							whiteSpace: "nowrap",
							padding: "10px 5px 0",
							boxSizing: "border-box"
						}}
					>
						{Array.from(
							Array(Math.max(1, parseInt(monthwidth / 500)))
						).map(monthBuilder(iterator))}
					</div>
				);

				lastMonth = monthstart;
			}

			i++;
		}

		return (
			<thead key="xdiv4">
				<tr>
					<td />
					<td
						className="sticky"
						style={{
							height: 30,
							top: 0,
							background: "white",
							zIndex: 40
						}}
					>
						<div
							style={{
								position: "relative",
								background: "white",
								width: width
							}}
						>
							{months}
						</div>
					</td>
				</tr>
				<tr key="xdiv3">
					<td
						ref={this.props.xref}
						rowSpan="3"
						key="xdiv2"
						className="sticky"
						style={{
							height: 20,
							top: 30,
							left: 0,
							boxSizing: "border-box",
							zIndex: 80
						}}
					>
						<div className="cal_head" key="xdiv1">
							{this.props.edit || this.props.app.ui.mode === "BOARD" ? null : <FilterInput />}
						</div>
					</td>
					<td
						className="sticky"
						style={{
							height: 20,
							top: 30,
							zIndex: 50
						}}
					>
						<div style={{ position: "relative", width: width }}>
							{weeks}
						</div>
					</td>
				</tr>
				<tr>
					<td
						className="sticky"
						style={{
							height: 20,
							top: 50,
							zIndex: 60
						}}
					>
						<div style={{ position: "relative", width: width }}>
							{weekdays}
						</div>
					</td>
				</tr>
				<tr>
					<td
						className="sticky"
						style={{
							height: 20,
							top: 70,
							zIndex: 70
						}}
					>
						<div style={{ position: "relative", width: width }}>
							{days}
						</div>
					</td>
				</tr>
			</thead>
		);
	}
}

export default inject("app")(observer(CalendarHeader));
