import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Header, Icon, Loader, Modal, Menu } from "semantic-ui-react";
import axios from "axios";
import LiteCodes from "./liteCodes";

// Import Common Stylesheets

class EditLite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            codes: {},
            error: false,
            open: false,
        };
        this.debounce = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.open && this.state.open && !this.state.codes.length)
            this.update();
    }

    activateLite(id) {
        this.props.edit.changeProperty(id, { hasLite: true });
        this.update(true);
    }

    update(getCodes = false) {
        const processId = this.props.process;
        if (!(processId in this.props.edit.state.data.processes)) return false;

        const getThem =
            getCodes || this.props.edit.state.data.processes[processId].hasLite;
        const codes = {
            id: this.props.edit.state.data.processes[processId].id,
            name: this.props.edit.state.data.processes[processId].name,
            codes: false,
        };

        if (getThem) {
            this.setState({ isLoading: true, error: false });
            axios
                .post(
                    ""+process.env.REACT_APP_BPO_URL+"/earthworks/InfraErpConnector.php",
                    {
                        action: "updateAndGetCodes",
                        data: {
                            [this.props.edit.state.data.id]: {
                                id: this.props.edit.state.data.id,
                                name: this.props.edit.state.data.name,
                                bstn: this.props.edit.state.data.bstn,
                                username: this.props.edit.state.data.username,
                                processes: {
                                    [processId]: this.props.edit.getLiteProcessData(
                                        processId
                                    ),
                                },
                            },
                        },
                    },
                    {
                        headers: {
                            BPOAuth: "6e469243-cf85-466b-92e9-e816f1b3376d",
                        },
                    }
                )
                .then((response) => {
                    // handle success
                    this.setState({
                        isLoading: false,
                        codes: response.data[0],
                        error: false,
                    });
                })
                .catch((error) => {
                    // handle error
                    this.setState({
                        isLoading: false,
                        codes: false,
                        error: true,
                    });
                });
        } else {
            this.setState({
                isLoading: false,
                codes: codes,
                error: false,
            });
        }
    }

    close() {
        this.setState({ open: false });
    }
    open() {
        this.setState({ open: true });
    }

    render() {
        return (
            <Modal
                centered={false}
                className="projectEditModal"
                open={this.state.open}
                onClose={(_) => this.close()}
                trigger={
                    this.props.edit.state.data.processes[this.props.process].hasLite === false ?
                    (<Button
                        icon="picon picon-lite"
                        size="mini"
                        className="bpolitebutton"
                        compact
                        onClick={(_) => this.open()}
                    />) : (
                        <Button
                        icon="picon picon-lite"
                        size="mini"
                        className="bpolitebutton green"
                        compact
                        onClick={(_) => this.open()}
                    />
                    )
                }
            >
                <Menu>
                    <Menu.Item header>
                        {this.props.t("edit.lite.heading", {
                            name:
                                "name" in this.state.codes
                                    ? this.state.codes.name
                                    : "?",
                        })}
                    </Menu.Item>
                    {"xid" in this.state.codes ? (
                        <Menu.Item
                            name="send"
                            as="a"
                            href={
                                ""+process.env.REACT_APP_BPO_URL+"/lite/codes.php?i=" +
                                this.state.codes.xid
                            }
                            target="_blank"
                        >
                            <Icon name="send" />{" "}
                            {this.props.t("print") +
                                " / " +
                                this.props.t("send")}
                        </Menu.Item>
                    ) : null}
                    <Menu.Menu position="right">
                        <Menu.Item
                            name="close it"
                            onClick={(_) => this.close()}
                        >
                            <Icon name="check" /> {this.props.t("ok")}
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>

                <Modal.Content>
                    {this.state.isLoading ? (
                        <Loader inverted size="large" active />
                    ) : this.state.error ? (
                        <Header className="connectionError" icon>
                            <Icon name="wifi" />
                            {this.props.t("loader.networkError")}
                            <Header.Subheader>
                                <Button onClick={(x) => this.update()}>
                                    {this.props.t("loader.tryAgain")}
                                </Button>
                            </Header.Subheader>
                        </Header>
                    ) : (
                        <div className="litecodes">
                            <div className="litecodes-header" />
                            {this.state.codes.codes ? (
                                <LiteCodes data={this.state.codes.codes} />
                            ) : (
                                <Button
                                    onClick={(e) =>
                                        this.activateLite(this.state.codes.id)
                                    }
                                >
                                    {this.props.t("edit.lite.create")}
                                </Button>
                            )}
                        </div>
                    )}
                </Modal.Content>
            </Modal>
        );
    }
}

export default withTranslation()(EditLite);
