import React from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import ClassDropdown from "../components/calendar/controls/classDropdown";
import { Input } from "semantic-ui-react";
import WahlImg from "../images/wahl.png";
import { arrayFilter } from "../helpers/calendar.js";
import { Link } from "react-router-dom";
import { Map, Marker, TileLayer, Popup } from "react-leaflet";
import { latLngBounds, AwesomeMarkers } from "leaflet";

const positionIsValid = statePos => {
	return !(
		!statePos ||
		statePos.lat === 0 ||
		statePos.lng === 0 ||
		statePos.zoom === 0
	);
};

class MapPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = { filter: "" };
	}

	componentDidMount() {
		if (this.props.app.ui.currentClass.includes("GROUPS")) {
			console.log(this.props.app.ui.currentClass);
			this.props.app.ui.setClassDropdown(false, { value: ["ALL"] });
		}
		console.log(this.props.app.ui.currentClass);
		this.props.appCtx.setEditButtons(
			<Link className="topbutton" to="/">
				<img alt="Back" src={WahlImg} />
			</Link>,
			null
		);
	}

	componentDidUpdate(prevProps) {}

	changeFilter(filter) {
		this.setState({ filter });
	}

	componentWillUnmount() {
		this.props.appCtx.setEditButtons(false);
	}

	render() {
		const filter = arrayFilter(this.state.filter);

		const resources = this.props.app.resources.getResourceLines();

		let bounds = false;
		const markers = [];
		const mapSettings = {};

		const colors = [
			{
				color: "white",
				background: "red"
			},
			{
				color: "white",
				background: "darkred"
			},
			{
				color: "black",
				background: "lightred"
			},
			{
				color: "white",
				background: "orange"
			},
			{
				color: "black",
				background: "beige"
			},
			{
				color: "white",
				background: "green"
			},
			{
				color: "white",
				background: "darkgreen"
			},
			{
				color: "black",
				background: "lightgreen"
			},
			{
				color: "white",
				background: "blue"
			},
			{
				color: "white",
				background: "darkblue"
			},
			{
				color: "black",
				background: "lightblue"
			},
			{
				color: "white",
				background: "purple"
			},
			{
				color: "white",
				background: "darkpurple"
			},
			{
				color: "black",
				background: "pink"
			},
			{
				color: "white",
				background: "cadetblue"
			},
			{
				color: "black",
				background: "white"
			},
			{
				color: "white",
				background: "gray"
			},
			{
				color: "black",
				background: "lightgray"
			},
			{
				color: "white",
				background: "black"
			}
		];

		for (let resource of resources) {
			const pos = resource.lastLocation;
			if (!positionIsValid(pos)) continue;

			if (!filter([resource.name, ""+resource.inventaryNumber])) continue;

			if (!bounds) bounds = latLngBounds([pos.lat, pos.lng]);
			bounds.extend([pos.lat, pos.lng]);

			const colorX =
				parseInt(resource.resclass.id.substr(0, 2), 16) % colors.length;

			markers.push({
				latlng: [pos.lat, pos.lng],
				zoom: pos.zoom,
				key: resource.id,
				name: resource.name,
				icon: AwesomeMarkers.icon({
					icon: resource.resclass.human ? "user" : "picon-excavator",
					markerColor: colors[colorX].background,
					iconColor: colors[colorX].color
				})
			});
		}

		if (!bounds) {
			mapSettings.center = [
				this.props.t("stdPos.lat", 50.8),
				this.props.t("stdPos.lng", 10.5)
			];
			mapSettings.zoom = this.props.t("stdPos.zoom", 6);
		} else if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
			mapSettings.center = markers[0].latlng;
			mapSettings.zoom = 14;
		} else {
			mapSettings.bounds = bounds.pad(0.3);
		}

		return (
			<div id="mapPage" className="mapPage">
				<div id="map_buttons" className="buttonLine">
					<Input
						className="icon"
						icon="search"
						placeholder={this.props.t("filter")}
						value={this.state.filter}
						onChange={(e, { value }) => this.changeFilter(value)}
					/>
					<ClassDropdown
						onChange={this.props.app.ui.setClassDropdown}
						value={this.props.app.ui.currentClass}
						options={this.props.app.resources.getClassDropdownOptions(
							this.props.t,
							"selection"
						)}
						props={this.props}
					/>
				</div>
				<Map {...mapSettings} id="map_map">
					<TileLayer url="https://maps.bpo-asphalt.de/tile.php?z={z}&x={x}&y={y}&key=dboth&type=standard" />
					{markers.map(m => (
						<Marker icon={m.icon} key={m.key} position={m.latlng}>
							<Popup>{m.name}</Popup>
						</Marker>
					))}
				</Map>
			</div>
		);
	}
}

export default inject("app")(withTranslation()(observer(MapPage)));
