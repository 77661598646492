import React from "react";
import {
	Icon
} from "semantic-ui-react";


const ClusterDetail = props => {
	return <div className="clusterDetail">
		<Icon name={props.icon} /><span>{props.text+"\n"}</span>
	</div>;
};

export default ClusterDetail;
