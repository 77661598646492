import React from "react";
import ZoomControl from "./controls/zoomControl";
import ClassDropdown from "./controls/classDropdown";
import CollisionWarner from "./collisionWarner";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Button, Loader, Popup, Menu, Modal, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import DuravermeerExport from "../exports/duravermeerExport";
import SchuetzExport from "../exports/schuetzExport";
import BackesExport from "../exports/backesExport";
import DetailExporter from "../exports/detailExporter";
import Bauzeitenplan from "../exports/bauzeitenplan";
import StandardExport from "../exports/standardExport";
import WumExport from "../exports/wumExport";
import TarmacMaterialExport from "../exports/tarmacMaterialExport";
import ProcessTypeChooser from "./controls/processTypeChooser";
import AGFModal from "../addons/AGFModal";
import IntervalChooser from "./projecteditview/intervalChooser";
import TrucksCountErrors from "./projecteditview/TrucksCountErrors";
import _ from "lodash";

const CalendarButtons = (props) => {

	let icons = {
		dateAscending: "sort content ascending",
		dateDescending: "sort content descending",
		nameDescending: "sort alphabet descending",
		nameAscending: "sort alphabet ascending"
	};

	let next = {
		dateAscending: "dateDescending",
		dateDescending: "nameAscending",
		nameAscending: "nameDescending",
		nameDescending: "dateAscending"
	};

	let dx = [];

	let errorsOnDate = Object.entries(props.app.resources.trucksCountValidationErrors).map(([key, x]) => {
		if(x.length > 0 && props.edit !== undefined && props.edit !== null){
			let process = props.edit.state.data.processes[key.split('#')[0]];
			let err = {};
			err.pid = key.split('#')[0];
			err.pname = process.name;
			err.date = key.split('#')[1];
			err.errors = x;

			return err
		}else if(props.edit === undefined || props.edit === null){
			props.app.resources.updatevalidationErrors({});
		}
		return null;
	});
	let errorsGrouped = _.groupBy(errorsOnDate, 'pid');
    Object.entries(errorsGrouped).map(([key, group]) => { 
		group = _.filter(group, v => v !== null);
		if(group.length > 0){
			let process = props.edit.state.data.processes[key];
			dx.push(<br />);
			dx.push(
				<TrucksCountErrors process={process} errors={group}  />
			);
		}
		
		return "";
	});
	
	

	return (
		<div className="buttonLine" id="calendar_buttons">
			<div className="buttonBlock zoomButtonBlock">
				<ZoomControl />
				{props.app.ui.isLoading && props.app.ui.backgroundLoading ? (
					<Loader active inline />
				) : null}
			</div>
			{props.edit ? (
				<React.Fragment>
					{window.bpo ? (
						<div className="buttonBlock editButtonBlock">
							<Button.Group>
								{props.edit.state.data.editable ? (
									<>
									<Button
										onClick={(_) => {
											console.log(
												props.app.ui.saveNecessary(),
												props.edit.state.neverChanged,
												props.edit.state
											);
											!props.app.ui.saveNecessary() &&
											props.edit.state.neverChanged
												? props.edit.openBAP()
												: props.app.ui.openMessage("edit.saveFirst");
										}}
									>
										{props.t("edit.bpo.openBAP")}
									</Button>
									<form style={{display:'none'}} action={process.env.REACT_APP_BPO_URL+"/?newbauprojekt"} method="POST">
        							  <textarea name="infra" value={props.edit.state.hidden_form_value} />
									  <input name="PHPSESSID" value={localStorage.getItem("binfra_phpsessid")} />
        							  <button type="submit" ref={props.edit.myRef} >
        							    Submit
        							  </button>
        							</form>
									</>
								) : null}
								<Button
									onClick={(_) => {
										console.log(
											props.app.ui.saveNecessary(),
											props.edit.state.neverChanged,
											props.edit.state
										);
										!props.app.ui.saveNecessary() &&
										props.edit.state.neverChanged
											? props.edit.openMixtureLetter()
											: props.app.ui.openMessage("edit.saveFirst");
									}}
								>
									{props.t("edit.bpo.mixtureLetter")}
								</Button>
							</Button.Group>
						</div>
					) : null}

					{props.edit.state.data.editable ? (
						<div className="buttonBlock actionButtonBlock">
							<Button.Group>
								<Button
									icon="undo alternate"
									disabled={props.edit.state.history.length < 2}
									onClick={() => props.edit.undo()}
								/>
								<Button
									icon="redo alternate"
									disabled={props.edit.state.forwardHistory.length < 1}
									onClick={() => props.edit.redo()}
								/>
							</Button.Group>
							<Popup content={props.t("calButtons.lockMoving")} trigger={<Button
									icon={props.edit.state.data.fixed ? "lock" : "lock open"}
									onClick={() => props.edit.setFixed(!props.edit.state.data.fixed)}
							/>} />
							{props.app.ui.modules.has("AGF") ? (
								<AGFModal size={null} projectId={props.edit.state.pid} />
							) : null}
							<Bauzeitenplan editState={props.edit.state} />
							<IntervalChooser
								edit={props.edit}
								templates={props.app.basedata.processTemplates}
							/>
							<Button
								icon="clone"
								active={props.edit.state.multiMode}
								labelPosition="left"
								content={props.t("calButtons.multiMode")}
								onClick={(_) =>
									props.edit.setMultiMode(!props.edit.state.multiMode)
								}
							/>
							<Button
								icon="copy"
								active={!!props.edit.state.copyOrigins.size}
								labelPosition="left"
								content={props.t("calButtons.copy")}
								onClick={(_) => props.edit.copyMode()}
							/>
							<Button
								icon="trash alternate"
								active={props.edit.state.deleteMode}
								labelPosition="left"
								content={props.t("calButtons.deleteMode")}
								onClick={(_) =>
									props.edit.setDeleteMode(!props.edit.state.deleteMode)
								}
							/>
							<ProcessTypeChooser
								onSelect={(template) => props.edit.addProcess(template)}
								trigger={
									<Button
										labelPosition="left"
										content={props.t("calButtons.addProcess")}
										icon="add"
									/>
								}
							/>

							<Modal
								centered={false}
								className="collisionModal"
								trigger={
									<Button
										icon="warning sign"
										onClick={ (e) => props.edit.setErrorsModalOpen(true) }
										className={
											props.app.resources.getValidationErrorsCount() > 0
												? "collision"
												: ""
										}
									/>
								}
								open={props.edit.state.trucksErrorModalOpen === true}
								onClose={e => props.edit.setErrorsModalOpen(false)}
							>
								
								<React.Fragment>
									<Menu>
										<Menu.Item header>
											{props.t("conflicts")}
										</Menu.Item>
										<Menu.Menu position="right">
											<Menu.Item
												name="close it"
												onClick={e => props.edit.setErrorsModalOpen(false)}
											>
												<Icon name="close" />
											</Menu.Item>
										</Menu.Menu>
									</Menu>
									<Modal.Content>
										<div className="collisionWrapper">{dx}</div>
									</Modal.Content>
								</React.Fragment>
							</Modal>
							
						</div>
					) : null}
				</React.Fragment>
			) : (
				<React.Fragment>
					<div className="buttonBlock boardButtonBlock">
						{props.app.ui.rights.has("VIEW_RESOURCES") ||
						props.app.ui.rights.has("VIEW_BOARD") ? (
							<Button.Group>
								<Button
									active={props.app.ui.mode === "CALENDAR"}
									onClick={(_) => props.app.ui.setMode("CALENDAR")}
								>
									{props.t("calButtons.mode.calendar")}
								</Button>
								{props.app.ui.rights.has("VIEW_RESOURCES") ? (
									<Button
										active={props.app.ui.mode === "RESOURCES"}
										onClick={(_) => props.app.ui.setMode("RESOURCES")}
									>
										{props.t("calButtons.mode.disposition")}
									</Button>
								) : null}
								{props.app.ui.rights.has("VIEW_BOARD") ? (
									<Button
										active={props.app.ui.mode === "BOARD"}
										onClick={(_) => props.app.ui.setMode("BOARD")}
									>
										{props.t("calButtons.mode.board")}
									</Button>
								) : null}
							</Button.Group>
						) : null}
					</div>

					<div className="buttonBlock exportButtonBlock">
						{props.app.ui.rights.has("VIEW_RESOURCES") ||
						props.app.ui.rights.has("VIEW_BOARD") ? (
							<>
								<Button.Group>
									<Button
										icon="undo alternate"
										disabled={props.app.ui.resourceDeploymentHistory?.history.length < 1}
										onClick={(_) => props.app.ui.undoResourceDelete()}
									/>
									<Button
										icon="redo alternate"
										disabled={props.app.ui.resourceDeploymentHistory?.forwardHistory.length < 1}
										onClick={(_) => props.app.ui.redoResourceDelete()}
									/>
								</Button.Group>								
								<Button
									icon="clone" 
									active={props.app.ui.resourceMultiselect}
									onClick={(_) => props.app.ui.setResourceMultiselect()}
								/> 
								<Button
									icon="trash alternate" 
									onClick={(_) => props.app.ui.deleteResources()}
								/> 
							</>
						) : null}
						{window.bpo && props.app.ui.modules.has("EXPORT_DURAVERMEER") ? (
							<DuravermeerExport />
						) : null}
						{window.bpo && props.app.ui.modules.has("EXPORT_WUM") ? (
							<WumExport />
						) : null}

						{/* EXPORT_TARMARC_MATERIAL is the actual one temporaroly used PROCESS_BSTN */}
						{window.bpo && props.app.ui.modules.has("PROCESS_BSTN") ? (
							<TarmacMaterialExport />
						) : null}
						
						{window.bpo && props.app.ui.modules.has("EXPORT_SCHUETZ") ? (
							<SchuetzExport />
						) : null}
						{window.bpo && props.app.ui.modules.has("EXPORT_STANDARD") ? (
							<StandardExport />
						) : null}
						{window.bpo && props.app.ui.modules.has("EXPORT_BACKES") ? (
							<BackesExport />
						) : null}
						{props.app.ui.mode === "BOARD" ? <DetailExporter /> : null}
						{props.app.ui.modules.has("DAY_VIEW") &&
						(props.app.ui.rights.has("VIEW_RESOURCES") ||
							props.app.ui.rights.has("VIEW_BOARD")) ? (
							<Button
								onClick={() => props.app.ui.setDayView(true)}
								icon="calendar alternate"
							/>
						) : null}
						{props.app.ui.mode === "RESOURCES" ? (
							<React.Fragment>
								{props.app.ui.rights.has("COLLISION_CHECKER") ? (
									<CollisionWarner
										lastChange={props.app.resources.lastCollisionRelevantChange}
									/>
								) : null}
								{props.app.ui.rights.has("EDIT_GROUPS") ? (
									<Button as={Link} to="/groups" icon="group" />
								) : null}
								{props.app.ui.modules.has("RESOURCE_MAP") &&
								props.app.ui.rights.has("RESOURCE_MAP") ? (
									<Button as={Link} to="/map" icon="map marker alternate" />
								) : null}
							</React.Fragment>
						) : null}
						{props.app.ui.mode !== "CALENDAR" ? (
							<ClassDropdown
								onChange={props.app.ui.setClassDropdown}
								value={Array.from(props.app.ui.currentClass)}
								options={props.app.resources.getClassDropdownOptions(
									props.t,
									props.app.ui.mode !== "BOARD"
								)}
								onSearchChange={props.app.ui.setClassDropdownSearch}
								props={props}
							/>
						) : props.app.ui.rights.has("CREATE_PROJECTS") ? (
							<Button
								as={Link}
								to="/project"
								content={props.t("calButtons.addProject")}
								icon={"plus"}
								labelPosition="left"
							/>
						) : null}
						{props.app.ui.mode !== "CALENDAR" ? (
							<Button onClick={() => props.app.ui.setGroupSortMethod(next[props.app.ui.groupSortMethod])} icon={icons[props.app.ui.groupSortMethod]} size="small" />
						) : null}
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default inject("app")(withTranslation()(observer(CalendarButtons)));
