import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Tab, Table, Button, Segment, Icon, Header } from "semantic-ui-react";
import ConfirmButton from "../controls/confirmButton";
import BlurChangeInput from "../controls/blurChangeInput";
import MaterialChooser from "../../basedata/selects/materialChooser";

const EditOrders = (props) => {
	const { t } = useTranslation();
	const state = props.data.state.formData.orders;
	if (!state.mergeable && props.data.props.app.basedata.company.name !== "arkil_denmark") {
		return (
			<Tab.Pane>
				<Segment placeholder>
					<Header icon>
						<Icon name="cubes" />
						{t("edit.difference.header", {
							type: t("edit.difference.types.orders"),
						})}
						<Header.Subheader>
							{t("edit.difference.message", {
								type: t("edit.difference.types.orders"),
							})}
						</Header.Subheader>
					</Header>
					{!props.edit.state.data.editable ||
					!!props.data.state.formData.bpo.length ? null : (
						<ConfirmButton
							trigger={
								<Button negative>
									{t("edit.difference.action", {
										type: t("edit.difference.types.orders"),
									})}
								</Button>
							}
							onClick={() => props.data.resetOrderDispo("orders")}
						/>
					)}
				</Segment>
			</Tab.Pane>
		);
	}

	let noOrderDispo = (
		<Fragment>
			<Table.Body>
				<Table.Row>
					<Table.Cell>
						<Segment placeholder>
							<Header icon>
								<Icon name="cubes" />
								{t("edit.nodata.orders.message")}
							</Header>
						</Segment>
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Fragment>
	);

	let orderDispo = (
		<Fragment>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>
						{t("edit.fields.orderDelMat.label")}
					</Table.HeaderCell>
					<Table.HeaderCell>
						{t("edit.fields.orderAmount.label")}
					</Table.HeaderCell>
					{props.extOrderData ? (
						<React.Fragment>
							<Table.HeaderCell>
								{t("edit.fields.orderThickness.label")}
							</Table.HeaderCell>
							<Table.HeaderCell>
								{t("edit.fields.orderArea.label")}
							</Table.HeaderCell>
						</React.Fragment>
					) : null}
					{!props.edit.state.data.editable ||
					!!props.data.state.formData.bpo.length ? null : (
						<Table.HeaderCell />
					)}
				</Table.Row>
			</Table.Header>

			<Table.Body>
				{Object.entries(state.data).map(([key, x]) => (
					<Table.Row key={x.key}>
						<Table.Cell>
							<MaterialChooser
								agfData={props.edit.state.agfAcceptedMaterials}
								resetForceOpen={(_) => props.data.resetForceOpen()}
								forceOpen={
									"orders" + props.data.state.matChooserForceOpen === x.key
								}
								material={x.material}
								sortUp={props.edit.state.suppliers}
								supplier={x.supplier}
								onChange={(supplier, material) => {
									let restMaterial = [];
									if (Array.isArray(material)) {
										let firstMaterial = material.shift();
										restMaterial = [...material];
										material = firstMaterial;
									}
									props.data.changeOrderDispo(
										"orders",
										false,
										{ supplier, material },
										x.reference
									);
									if (restMaterial.length) {
										props.data.addOrderDispo(
											"orders",
											false,
											restMaterial,
											supplier
										);
									}
									props.edit.saveHistory();
								}}
								disabled={
									!props.edit.state.data.editable ||
									!!props.data.state.formData.bpo.length
								}
							/>
						</Table.Cell>
						<Table.Cell>
							<BlurChangeInput
								placeholder={t("edit.fields.orderAmount.placeholder")}
								value={x.amount}
								type="number"
								fluid
								onBlur={(e) => {
									props.data.changeOrderDispo(
										"orders",
										"amount",
										isNaN(parseFloat(x.amount.replace(/,/, "."))) ||
											parseFloat(x.amount.replace(/,/, ".")) < 0
											? 0
											: parseFloat(x.amount.replace(/,/, ".")),
										x.reference
									);
									props.edit.saveHistory();
								}}
								onChange={(e, { value }) =>
									props.data.changeOrderDispo(
										"orders",
										"amount",
										value,
										x.reference,
										true
									)
								}
								disabled={
									!props.edit.state.data.editable ||
									!!props.data.state.formData.bpo.length
								}
							/>
						</Table.Cell>
						{props.extOrderData ? (
							<React.Fragment>
								<Table.Cell>
									<BlurChangeInput
										placeholder={t("edit.fields.orderThickness.placeholder")}
										value={x.thickness}
										type="number"
										fluid
										onBlur={(e) => {
											props.data.changeOrderDispo(
												"orders",
												"thickness",
												isNaN(parseFloat(x.thickness.replace(/,/, "."))) ||
													parseFloat(x.thickness.replace(/,/, ".")) < 0
													? 0
													: parseFloat(x.thickness.replace(/,/, ".")),
												x.reference
											);
											props.edit.saveHistory();
										}}
										onChange={(e, { value }) =>
											props.data.changeOrderDispo(
												"orders",
												"thickness",
												value,
												x.reference,
												true
											)
										}
										disabled={
											!props.edit.state.data.editable ||
											!!props.data.state.formData.bpo.length
										}
									/>
								</Table.Cell>
								<Table.Cell>
									<BlurChangeInput
										placeholder={t("edit.fields.orderArea.placeholder")}
										value={x.area}
										type="number"
										fluid
										onBlur={(e) => {
											props.data.changeOrderDispo(
												"orders",
												"area",
												isNaN(parseFloat(x.area.replace(/,/, "."))) ||
													parseFloat(x.area.replace(/,/, ".")) < 0
													? 0
													: parseFloat(x.area.replace(/,/, ".")),
												x.reference
											);
											props.edit.saveHistory();
										}}
										onChange={(e, { value }) =>
											props.data.changeOrderDispo(
												"orders",
												"area",
												value,
												x.reference,
												true
											)
										}
										disabled={
											!props.edit.state.data.editable ||
											!!props.data.state.formData.bpo.length
										}
									/>
								</Table.Cell>
									</React.Fragment>
								) : null}
						{!props.edit.state.data.editable ||
						!!props.data.state.formData.bpo.length ? null : (
							<Table.Cell>
								<ConfirmButton
									ok="yes"
									cancel="no"
									trigger={
										<Button
											floated="right"
											icon="trash alternate"
											size="small"
										/>
									}
									onClick={(e) => {
										props.data.changeOrderDispo(
											"orders",
											"deleted",
											true,
											x.reference
										);
										props.edit.saveHistory();
									}}
								/>
							</Table.Cell>
						)}
					</Table.Row>
				))}
			</Table.Body>
		</Fragment>
	);

	return (
		<Tab.Pane>
			<Table singleLine>
				{Object.entries(state.data).length === 0 &&
				state.data.constructor === Object
					? noOrderDispo
					: orderDispo}
				{!props.edit.state.data.editable ||
				!!props.data.state.formData.bpo.length ? null : (
					<Table.Footer fullWidth>
						<Table.Row>
							<Table.HeaderCell colSpan={props.extOrderData ? 5 : 3}>
								<Button
									floated="right"
									icon
									labelPosition="left"
									primary
									size="small"
									onClick={(_) =>
										props.data.addOrderDispo(
											"orders",
											Object.entries(state.data).length
										)
									}
								>
									<Icon name="cubes" /> {t("edit.buttons.newOrder")}
								</Button>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				)}
			</Table>
		</Tab.Pane>
	);
};

export default EditOrders;
