import React from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { strToDate } from "../../../helpers/calendar"; 
import moment from "moment";

class ProjectResourceLine extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};   
	}

	static getX(props, date) {
		return (
			((date.getTime() - props.app.ui.view.start.getTime()) /
				props.app.ui.calDuration) *
			props.xwidth
		);
	}

	static getDate(props, x) {
		return (props.app.ui.calDuration * x) / props.xwidth;
	}

	static getWidth(props, x) {
		return (x * props.xwidth) / props.app.ui.calDuration;
	}
	
	handleResourceClick(day, resourceId) { 
		if (!this.props.app.resources.resources.has(resourceId)) return;
		const data = this.props.data; 
		const jobs = data.days[day];
		const resource = this.props.app.resources.resources.get(resourceId); 
		this.props.app.ui.setResourceSeleceted(jobs, resource);
		//jobs.forEach(job => job.dispose(resource, "REMOVE"));  
	} 

	render() { 
		const isGroupLine = this.props.group === true;
		const width =
			((this.props.app.ui.view.end - this.props.app.ui.view.start) /
				this.props.app.ui.calDuration) *
			this.props.xwidth;

		const data = this.props.data;
		const boxes = []; 

		const lineHeight = isGroupLine ? 20 : 30;
		const padding = 0;
		const boxHeight = lineHeight - 2 * padding;
		// const planningsPavingdata = this.props.pstate.planningsPavingdataArray; 
 

		const resourceDeploymentSelected = this.props.app.ui.resourceDeploymentSelected;

		for (let [personalId, dateData] of Object.entries(data.days)) { 
			let start = ProjectResourceLine.getX(
				this.props,
				strToDate(personalId) 
			);
			let end = ProjectResourceLine.getX(
				this.props,
				strToDate(personalId, 1)
			);
			let processes = dateData.map(x => x.process.id); 

			let isBeingMoved = this.props.pstate.dayMap.has(personalId);

			const isNight = dateData.reduce((a,c) => c.isNight||a,false);

			let pos = 0;
			let offset = 0;

			if (this.props.pstate.pid === "") {
			} else if (
				isBeingMoved &&
				(this.props.pstate.sub === "main" ||
					processes.includes(this.props.pstate.sub))
			) {
				offset = this.props.pstate.dayMap.get(personalId);
			}

			const hovered =
				this.props.app.ui.mouseOver.day === personalId &&
				this.props.app.ui.mouseOver.resource === data.resource.id;

			let marked = ''; 
			if(resourceDeploymentSelected?.length) {
				//marked = resourceDeploymentSelected.filter((i) => i.resource.id === data.resource.id && i.jobs[0].bpo === dateData[0].bpo && resourceDeploymentSelected.length );
				marked = resourceDeploymentSelected.filter((i) => i.resource.id === data.resource.id && moment(i.jobs[0].start).format("YYYY-MM-DD") === personalId && resourceDeploymentSelected.length );
			} 

			let rollerPerformancelabel = '';
			let rollerPerformance = '';
			// let rollerPerformanceNeeded;
			const rollerPasses = dateData[0].roller_data?.rollerPasses ?? 1;

			if(dateData[0].roller_data?.roller_resources?.length && this.props.app.ui.modules.has("ROLLER_CONCEPTS") && this.props.app.ui.rights.has("READ_ROLLER_MASTERDATA")){
				const resData = dateData[0].roller_data?.roller_resources.filter((cur) => {
					return cur.resource === data.resource.id
				}); 				
				if(resData.length)
				rollerPerformance = Math.round( (0.9*parseFloat(resData[0].rollerBandage)*parseFloat(resData[0].rollerSpeed)*50)/parseFloat(rollerPasses) ); 
				if(rollerPerformance)
					rollerPerformancelabel = rollerPerformance.toLocaleString('en-DE') + ' m<sup>2</sup>/ h';	
 
				/*
				if(dateData[0].bpo && planningsPavingdata[dateData[0].bpo] !== undefined && rollerPerformance ){
					rollerPerformanceNeeded = Math.round(planningsPavingdata[dateData[0].bpo].roller_performance_need).toLocaleString('en-DE');
					rollerPerformancelabel = rollerPerformance.toLocaleString('en-DE') + '/' + rollerPerformanceNeeded.toLocaleString('en-DE');
				}
				*/
			}
			boxes.push(
				<div
					key={personalId}
					className={
						(offset !== 0 || pos !== 0
							? "standard_box moved"
							: "standard_box") + (hovered ? " hovered" : "") + (isNight ? " night" : "") + 
							(marked.length ? " marked" : "")
					}
					onMouseEnter={e =>
						this.props.app.ui.setMouseOver({
							projects: [],
							processes: processes,
							resource: data.resource.id,
							day: personalId
						})
					}  
					onClick={e => this.handleResourceClick(personalId, data.resource.id)}
					onMouseLeave={e => this.props.app.ui.resetMouseOver()}
					style={{
						cursor: "pointer",
						position: "absolute",
						left: start + 1 + offset,
						width: end - start - 1,
						height: boxHeight - 1,
						top: padding
					}}
				><div className="res_perf_data" dangerouslySetInnerHTML={{ __html: rollerPerformancelabel }}></div></div>
			);
		}

		return (
			<tr key={"tr" + data.resource.id} className={"project_resource"+(isGroupLine ? " groupline": "")}>
				<td
					className="project_resource_head sticky"
					style={{
						cursor: "pointer",
						left: 0,
						zIndex: 3,
						verticalAlign: "top"
					}}
				>
					<div
						className="resource_name"
						style={{
							whiteSpace: "nowrap",
							height: lineHeight
						}}
						onClick={
							("group" in this.props) && this.props.group !== true ? this.props.group : null
						}
					>
						{isGroupLine ? null : (<div
							className={
								"imageInner" +
								(data.isRentalResource ? " rentalResource" : "") +
								(data.isGroup ? " iiGroup picon picon-group" : "")
							}
							style={
								data.isRentalResource || data.isGroup
									? null
									: {
											backgroundImage:
												"url(" +
												data.resource.imageUrl +
												")"
									  }
							}
						>
							{data.isRentalResource
								? this.props
										.t("resourceChooser.rent")
										.substr(0, 1)
								: ""}
						</div>)}
						<div className="nameInner">{data.resource.name}</div>
					</div>
				</td>
				<td>
					<div
						className="project_body"
						style={{
							zIndex: 2,
							width: width,
							height: lineHeight,
							position: "relative"
						}}
					>
						{boxes}
					</div>
				</td>
			</tr>
		);
	}
}

export default inject("app")(withTranslation()(observer(ProjectResourceLine)));
