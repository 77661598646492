import React from "react";
import moment from "moment";
import { observer, inject } from "mobx-react";

const VerticalLines = ({ app:{basedata}, calDuration, xwidth, view, snap, xheight }) => {
	const width = ((view.end - view.start) / calDuration) * xwidth;
	const viewstart = moment(view.start).startOf("day");
	const viewend = moment(view.end).startOf("day");
	const lines = [];
	let i = 0;
	for (
		let iterator = viewstart.clone();
		iterator <= viewend;
		iterator.add(1, "day")
	) {
		let start = iterator.valueOf();
		let end = iterator
			.clone()
			.endOf("day")
			.valueOf();
		let left = ((start - view.start) / calDuration) * xwidth;
		let width = ((end - start) / calDuration) * xwidth;

		const working = basedata.checkWorkingDay(iterator);


		lines.push(
			<div
				key={"xline" + i}
				className={!working.state ? "holiday vertical_line" : "vertical_line"}
				style={{
					position: "absolute",
					left: left,
					width: width,
					bottom: 0,
					height: xheight
				}}
			></div>
		);
		i++;
	}

	return (
		<tr>
		<td />
		<td className="sticky" style={
			{
				bottom: 0
			}
		}><div style={{width: width,
						position: "relative"}}>{
			lines
		}</div></td>
	</tr>
	);
};

export default inject("app")(observer(VerticalLines));
