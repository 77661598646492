import React from "react";
import {
	Modal,
	Button,
	Menu,
	Icon,
	Dropdown,
	Message,
	Table,
} from "semantic-ui-react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";

class Codes extends React.Component {
	constructor(props) {
		super(props);
		this.close = this.close.bind(this);
		this.open = this.open.bind(this);

		const options = this.props.app.resources.getClassDropdownOptions(
			false,
			false
		);

		this.state = {
			open: false,
			options: options,
			category: 0,
		};
	}

	close() {
		this.setState({ open: false });
	}

	open() {
		this.setState({ open: true });
	}

	formatCode(code){
		return code.match(/.{1,3}/g).join("  ");
	}

	render() {
		const option = this.state.options[this.state.category];
		const title = this.props.t("questions.codes") + " " + option.name;
		const codes = [];
		for (let resource of this.props.app.resources.resources.values()) {
			if (
				resource.deleted ||
				!resource.resclass ||
				resource.resclass.id !== option.id
			)
				continue;
			let code = resource.appCode;
			if (resource.appCode.length === 0){
				code = <em>{this.props.t("questions.noCode")}</em>;
			} else {
				code = this.formatCode(code);
			}
			codes.push({ name: resource.name, code });
		}

		return (
			<React.Fragment>
				<Modal
					centered={false}
					className="reportModal xModal"
					trigger={
						<Button
							labelPosition="left"
							content={this.props.t("questions.codes")}
							icon="users"
							onClick={this.open}
						/>
					}
					open={this.state.open}
					onClose={this.close}
				>
					<Menu>
						<Menu.Item header>{this.props.t("questions.codes")}</Menu.Item>

						<ReactToPrint
							documentTitle={title}
							trigger={() => {
								return (
									<Menu.Item name="print">
										<Icon name="print" />{" "}
										{this.props.t("questions.reports.print")}
									</Menu.Item>
								);
							}}
							content={() => this.componentRef}
						/>

						<Menu.Menu position="right">
							<Menu.Item name="close it" onClick={this.close}>
								<Icon name="close" /> {this.props.t("close")}
							</Menu.Item>
						</Menu.Menu>
					</Menu>

					<Modal.Content>
						{this.state.open ? (
							<div className="codesWrapper">
								<div ref={(el) => (this.componentRef = el)}>
									<Dropdown
										search
										selection
										onChange={(e, { value }) =>
											this.setState({ category: value })
										}
										value={this.state.category}
										options={this.state.options.map((x, i) => {
											return { key: x.id, value: i, text: x.name };
										})}
									/>
								
								{codes.length > 0 ? <Table definition>
									<Table.Body>
										{codes
											.sort((a, b) => a.name.localeCompare(b.name))
											.map((x) => (
												<Table.Row>
													<Table.Cell>{x.name}</Table.Cell>
													<Table.Cell>{x.code}</Table.Cell>
												</Table.Row>
											))}
									</Table.Body>
								</Table> : <Message
                    fluid
                    header={this.props.t("questions.noCodes")}
                  />}</div>
							</div>
						) : null}
					</Modal.Content>
				</Modal>
			</React.Fragment>
		);
	}
}

export default withTranslation()(inject("app")(observer(Codes)));
