import React from "react";
import { strToDate } from "../../../helpers/calendar";

const WhiteMarkers = ({ days, getX, boxHeight, hide }) => {
	return (
		<React.Fragment>
			{Array.from(days).map(x => {
				const start = getX(strToDate(x));
				const end = getX(strToDate(x, 1));

				return (
					<div
						key={"wm"+x}
						className="whitemarker_box"
						style={{
							display: hide ? "none" : "block",
							pointerEvents: "none",
							position: "absolute",
							left: start + 1,
							width: end - start - 1,
							height: boxHeight - 1
						}}
					/>
				);
			})}
		</React.Fragment>
	);
};

export default WhiteMarkers;
