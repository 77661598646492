import React, { useState } from "react";
import { Modal, Button, Menu, Form, Icon, Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import Color from "color";
import { DateRangePicker } from "react-dates";

export default function IntervalChooser({ edit, templates }) {
	const [open, setOpen] = useState(false);

	const [process, setProcess] = useState(false);

	const [onlyWorkdays, setOnlyWorkdays] = useState(true);

	const [focusedInput, setFocusedInput] = useState(null);

	const [dateInterval, setDateInterval] = useState({start: null, end: null});

	const { t } = useTranslation();

	function save() {
		edit.chooseInterval(process,dateInterval, onlyWorkdays);
		setOpen(false);
		setProcess(false);
		setOnlyWorkdays(true);
		setDateInterval({start: null, end: null});
		setFocusedInput(null);
	}

	return (
		<Modal
			className="xModal"
			trigger={
				<Button
					icon="calendar plus"
					labelPosition="left"
					content={t("edit.chooseInterval.button")}
					onClick={() => setOpen(true)}
				/>
			}
			open={open}
			onClose={() => setOpen(false)}
		>
			<Menu>
				<Menu.Item header>{t("edit.chooseInterval.title")}</Menu.Item>
				<Menu.Menu position="right">
					<Menu.Item name="close it" onClick={() => setOpen(false)}>
						<Icon name="close" /> {t("cancel")}
					</Menu.Item>
					<Menu.Item name="save it" onClick={() => save()}>
						<Icon name="check" /> {t("ok")}
					</Menu.Item>
				</Menu.Menu>
			</Menu>
			<Modal.Content>
				<Form>
				<Form.Field>
						<label>{t("edit.chooseInterval.process")}</label>
						<Dropdown
							className="wideDrowdown"
							selection
							placeholder={t("choose.initial")}
							onChange={(e, data) => {
								setProcess(data.value);
							}}
							options={
								Object.values(edit.state.data.processes).filter(x => !x.deleted && templates.has(x.template)).map((x) => {

									const t = templates.get(x.template);
									return {
										text: x.name,
										value: x.id,
										key: x.id,
										icon: "picon-" + t.icon,
											backgroundColor: t.color,
											color: new Color(t.color).isLight() ? "black" : "white",
										};
									})
							}
							value={process}
						/>
					</Form.Field>
					<Form.Field>
						<label>{t("edit.chooseInterval.interval")}</label>
						<DateRangePicker
                startDate={dateInterval.start} // momentPropTypes.momentObj or null,
                startDateId="dpicker_start" // PropTypes.string.isRequired,
                noBorder={true}
                endDate={dateInterval.end} // momentPropTypes.momentObj or null,
                endDateId="dpicker_end" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) =>
                  setDateInterval({
                    start: startDate,
                    end: endDate
                  })
                } // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => setFocusedInput(focusedInput )} // PropTypes.func.isRequired,
                startDatePlaceholderText={t("begin")}
                endDatePlaceholderText={t("end")}
                hideKeyboardShortcutsPanel
                isOutsideRange={() => false}
                minimumNights={0}
              />
					</Form.Field>
					<Form.Field>
						<label>{t("edit.chooseInterval.chooseDays")}</label>
						<Button.Group>
							<Button
								active={onlyWorkdays}
								onClick={() => setOnlyWorkdays(true)}
							>
								{t("edit.chooseInterval.onlyWorkdays")}
							</Button>
							<Button
								active={!onlyWorkdays}
								onClick={() => setOnlyWorkdays(false)}
							>
								{t("edit.chooseInterval.allDays")}
							</Button>
						</Button.Group>
					</Form.Field>
				</Form>
			</Modal.Content>
		</Modal>
	);
}
