import React from "react";
import { observer, inject } from "mobx-react";
import { Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";

class ZoomControl extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			timeout: null
		};
	}

	stop() {
		if (this.state.timeout) clearTimeout(this.state.timeout);
	}

	start(fn) {
		fn = fn.bind(this);
		this.stop();
		fn();
		this.setState({
			timeout: setTimeout(() => this.start(fn), 200)
		});
	}

	zoomIn() {
		this.props.app.ui.zoom(0.9);
	}
	zoomOut() {
		this.props.app.ui.zoom(1.1);
	}
	scrollLeft() {
		console.time("scrollLeft");
		this.props.app.ui.addTime(-1 * parseInt(this.props.app.ui.calDuration / 4));
		console.timeEnd("scrollLeft");
	}
	scrollRight() {
		this.props.app.ui.addTime(parseInt(this.props.app.ui.calDuration / 4));
	}
	resetCal() {
		console.log(new Date(),'calStart');
		this.props.app.ui.addTime(-5);
		this.props.app.ui.setCalStart(moment().startOf("day").valueOf());
		
	}

	render() {
		return (
			<React.Fragment>
				<Button.Group>
					<Button
						icon="angle double left"
						onMouseDown={() => this.start(this.scrollLeft)}
						onMouseUp={() => this.stop()}
					/>
					<Button
						icon="angle double right"
						onMouseDown={() => this.start(this.scrollRight)}
						onMouseUp={() => this.stop()}
					/>
				</Button.Group>
				<Button.Group>
					<Button
						icon="zoom in"
						onMouseDown={() => this.start(this.zoomIn)}
						onMouseUp={() => this.stop()}
					/>
					<Button
						icon="zoom out"
						onMouseDown={() => this.start(this.zoomOut)}
						onMouseUp={() => this.stop()}
					/>
				</Button.Group>
				<Button.Group>
					<Button
						content={this.props.t("today")}
						icon="calendar today icon"
						onMouseDown={() => this.start(this.resetCal)}
						onMouseUp={() => this.stop()}
					/>
					
				</Button.Group>
			</React.Fragment>
		);
	}
}

export default withTranslation()(inject("app")(observer(ZoomControl)));
