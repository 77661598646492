import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Popup, Icon, Table } from "semantic-ui-react";

const Answer = (props) => {
	const { t } = useTranslation();
	let answerText = "";
	if (props.answer._question.type !== "TIMESPAN")
		answerText = props.answer.answer;
	else {
		const x = props.answer.answer.split(",");
		let startTime = "";
		let endTime = "";
		let diff = "";
		let invalid = false;
		if (x.length === 2) {
			startTime = x[0];
			endTime = x[1];
		} else {
			invalid = true;
		}
		if (startTime.length && endTime.length) {
			let sT = moment(startTime, "HH:mm");
			let eT = moment(endTime, "HH:mm");
			if (eT < sT) eT.add(1, "day");
			diff = " (" + moment.utc(eT.diff(sT)).format("HH:mm") + " h)";
		}
		answerText = invalid ? "" : startTime + "–" + endTime + diff;
	}

	let signatureImage = props.answer.attachments?.signatureImage ?? "";

	const renderInfoPopup = (writer, date, is_read) => (
		<>
			<Popup
				content={t("questions.reports.answeredByAt", {
					writer: props.answer._answeredBy?.name ?? "",
					date: moment(props.answer.answeredAt).format("DD.MM.YYYY HH:mm"),
				})}
				position='left center'
				size="small"
				trigger={<Icon className="qQuestionInfoIcon no-print" size="tiny" circular inverted name="info" />}
			/>
			{is_read === "1" ? null : (
				<Icon className="qQuestionInfoIcon warning sign no-print" size="tiny" circular name="warning" style={{ animationName: 'redden', animationDuration: '4s', animationTimingFunction: 'linear', animationIterationCount: 'infinite' }} />
			)}
		</>
	);

	if (!answerText.length && !signatureImage.length) return (<Table.Cell></Table.Cell>);
	return (
		<>
			{props.type === "SIGNATURE" && answerText.length > 0 ? (
				<Table.Cell className="qAnswers">
					<div className="answerWrapper">
						<img src={`${process.env.REACT_APP_SERVER_URL}/getSignatureFile/${encodeURIComponent(answerText)}`} className="signatureImage" alt="Signature" loading="lazy" />
					</div>
					{renderInfoPopup(props.answer._answeredBy.name, props.answer.answeredAt, props.answer.is_read)}
				</Table.Cell>
			) : (
				<>
					<Table.Cell colSpan={signatureImage.length < 1 ? 2 : null} className="qAnswer">
						<div className="answerWrapper">{answerText}</div>
						{signatureImage.length < 1 ? (<>{renderInfoPopup(props.answer._answeredBy.name, props.answer.answeredAt, props.answer.is_read)}</>) : null}
					</Table.Cell>
					{signatureImage.length > 0 ? (
						<Table.Cell className="qAnswers">
							<div className="answerWrapper" style={{ display: 'flex' }}>
								<p>signature:</p>
								<img src={`${process.env.REACT_APP_SERVER_URL}/getSignatureFile/${encodeURIComponent(signatureImage)}`} className="signatureImage" alt="Signature" loading="lazy" />
							</div>
							{renderInfoPopup(props.answer._answeredBy.name, props.answer.answeredAt, props.answer.is_read)}
						</Table.Cell>
					) : null
					}
				</>
			)}

		</>
	);
};

export default Answer;
