import React, { Component } from "react";
import { Confirm } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

class ConfirmButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  open(e) {
    e.stopPropagation();
    e.preventDefault();
    if (this.props.checkSkip && this.props.checkSkip()){
        return this.props.onClick();
    }
    this.setState({ open: true });
  }

  close(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ open: false });
  }

  render() {
    return (
      <React.Fragment>
        {React.cloneElement(this.props.trigger, {
          onClick: e => this.open(e)
        })}
        <Confirm
          open={this.state.open}
          content={
            this.props.question
              ? Array.isArray(this.props.question)
                ? this.props.t.apply(null, this.props.question)
                : this.props.t(this.props.question)
              : this.props.t("areyousure")
          }
          cancelButton={
            this.props.cancel
              ? Array.isArray(this.props.cancel)
                ? this.props.t.apply(null, this.props.cancel)
                : this.props.t(this.props.cancel)
              : this.props.t("no")
          }
          confirmButton={
            this.props.ok
              ? Array.isArray(this.props.ok)
                ? this.props.t.apply(null, this.props.ok)
                : this.props.t(this.props.ok)
              : this.props.t("yes")
          }
          onConfirm={e => {
            this.close(e);
            this.props.onClick();
          }}
          onCancel={e => this.close(e)}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation()(ConfirmButton);
