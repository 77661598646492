import React from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import {Modal} from "semantic-ui-react";

const MessagePopup = (props) => {
	return (<Modal
		trigger={null}
		open={!!props.app.ui.message.length}
		onClose={_ => props.app.ui.closeMessage()}
		content={props.t(props.app.ui.message)}
		actions={[props.t("ok")]}
	/>);
};

export default inject("app")(withTranslation()(observer(MessagePopup)));
