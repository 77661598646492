import React from "react";
import { withTranslation } from "react-i18next";
import { 
	Table,
	Form
} from "semantic-ui-react";  
 
class ResourceCarbonForm extends React.Component {  
    
    constructor(props) {
		super(props); 
        this.state = { 
			job: props.data.data.jobs[0],    
			literPerKWH: '',
			gPerKWH: '',   
			literProHour: '',   
			carbonCalculatedValue: 0
        }
	}    

    componentWillMount(){  
        // eslint-disable-next-line
		const [resclass, resid] = this.props.resourceKey.split("#");
		if(!this.props.data.resourceClass.human){
			if(this.props.data.data.jobs[0].carbon_data?.length){ 
				const carbon_resources = this.props.data.data.jobs[0].carbon_data;
				const resData = carbon_resources.filter( (curVal, index) => {
					return curVal.resourceId === resid;
				})
				if(resData.length) {
					this.setState({
						literPerKWH: resData[0]?.literPerKWH,
						gPerKWH: resData[0]?.gPerKWH,   
						literProHour: resData[0]?.literProHour,   
					});
					setTimeout(() => {
						this.calculateCarbonperformance();
					}, 100);					
				}
			}			 
		}  
    }
    
    calculateCarbonperformance(){
        return;
	}	

	async handleCarbonPerformance() { 
		if( this.state.literPerKWH && this.state.gPerKWH && this.state.literProHour ) {		
			this.calculateCarbonperformance();
		}    

		const updatedList = [];	 
		const [resclass, resid] = this.props.resourceKey.split("#");
		let has = 0;
		let carbonData = this.state.job?.carbon_data; 
		if(carbonData?.length){  
			let carbon_resource = carbonData; 			
			// eslint-disable-next-line
			let resData = carbon_resource.map((curVal) => {
				if(curVal.resourceId === resid ){
					curVal.literPerKWH = parseFloat(this.state.literPerKWH) || 0; 
					curVal.gPerKWH = parseFloat(this.state.gPerKWH) || 0; 
					curVal.literProHour = parseFloat(this.state.literProHour) || 0; 
					has = 1;
				}
				return curVal;
			}); 
			if(!has){  
				let carbon_resources_temp = [ ...carbonData ];
				carbon_resources_temp.push({
					resourceId: resid,
                    resourceName: this.props.resourceClass.name,
					resourceClass: resclass,
					literPerKWH: parseFloat(this.state.literPerKWH), 
					gPerKWH: parseFloat(this.state.gPerKWH), 
					literProHour: parseFloat(this.state.literProHour), 
				}) 
				carbonData = carbon_resources_temp;
				this.state.job.setCarbondata(carbonData) 
			}
		} else { 
			carbonData = [];
			carbonData.push({
				resourceId: resid,
                resourceName: this.props.resourceClass.name,
                resourceClass: resclass,
				literPerKWH: parseFloat(this.state.literPerKWH), 
                gPerKWH: parseFloat(this.state.gPerKWH), 
                literProHour: parseFloat(this.state.literProHour), 
			}) 
			this.state.job.setCarbondata(carbonData)				 
		}      
		updatedList.push([this.state.job, 0]);  
		this.props.data.app.projects.move(updatedList);			  
	}    
  
 
    render(){   
        return (
            <div>
                <div className="roller-resources-calculator details"> 
                    <h4>{this.props.t("edit.panes.carbonconcept")}</h4>
                    <Table singleLine>
                        <Table.Body> 								 					
                            <Table.Row>
                                <Table.Cell>
                                    {this.props.t("edit.carbon_concept.labels.liter_per_kwh")}:
                                </Table.Cell>
                                <Table.Cell> 
                                    <Form.Field className='carbon-concept-NSPN-input'> 
                                        <Form.Input 
                                            placeholder=""  
                                            type="number" 
                                            min="0" 
                                            name="literPerKWH"
                                            value={this.state.literPerKWH}
                                            onChange={(event) => {  																	 
                                                this.setState({ literPerKWH: event.target.value })
                                                setTimeout( ()=> {
                                                    this.handleCarbonPerformance();
                                                }, 100)				 
                                            }}																      
                                            />
                                    </Form.Field>												
                                </Table.Cell>																													
                            </Table.Row>

                             								 					
                            <Table.Row>
                                <Table.Cell>
                                    {this.props.t("edit.carbon_concept.labels.g_per_kwh")}:
                                </Table.Cell>
                                <Table.Cell> 
                                    <Form.Field className='carbon-concept-NSPN-input'> 
                                        <Form.Input 
                                            placeholder=""  
                                            type="number" 
                                            min="0" 
                                            name="gPerKWH"
                                            value={this.state.gPerKWH}
                                            onChange={(event) => {  																	 
                                                this.setState({ gPerKWH: event.target.value })
                                                setTimeout( ()=> {
                                                    this.handleCarbonPerformance();
                                                }, 100)				 
                                            }}																      
                                            />
                                    </Form.Field>												
                                </Table.Cell>																													
                            </Table.Row>                            

                            <Table.Row>
                                <Table.Cell>
                                    {this.props.t("edit.carbon_concept.labels.liter_per_hour")}:
                                </Table.Cell>
                                <Table.Cell> 
                                    <Form.Field className='carbon-concept-NSPN-input'> 
                                        <Form.Input 
                                            placeholder=""  
                                            type="number" 
                                            min="0" 
                                            name="literProHour"
                                            value={this.state.literProHour}
                                            onChange={(event) => {  																	 
                                                this.setState({ literProHour: event.target.value })
                                                setTimeout( ()=> {
                                                    this.handleCarbonPerformance();
                                                }, 100)				 
                                            }}																      
                                            />
                                    </Form.Field>												
                                </Table.Cell>																													
                            </Table.Row>



                                

                        </Table.Body>
                    </Table>
                </div>
            </div>
        );
    }   

};
 
export default withTranslation()(ResourceCarbonForm);