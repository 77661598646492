import {
  types,
  applySnapshot,
  getSnapshot,
  isValidReference,
  getRoot,
} from "mobx-state-tree";
import { Project, ProjectJob } from "./projects.js";
import { Material, Supplier, TruckType, AbsenceType } from "./basedata.js";
import { strToDate } from "../helpers/calendar";

import omitBy from "lodash.omitby";
import pick from "lodash.pick";
import { Position, Resource, ResGroup } from "./resources";

export const Absence = types
  .model("Absence", {
    id: types.identifier,
    _type: types.late(() => types.reference(AbsenceType)),
    _resource: types.late(() => types.reference(Resource)),
    deleted: types.optional(types.boolean, false),
    date: types.string,
    collisionWhitelist: types.maybeNull(types.array(types.string)),
    updatedAt: types.optional(types.Date, () => new Date()),
  })
  .views((self) => ({
    get type() {
      if (isValidReference(() => self._type)) return self._type;
      return { name: "?", color: "white" };
    },
    get resource() {
      if (isValidReference(() => self._resource)) return self._resource;
      return null;
    },
    get start() {
      return strToDate(self.date);
    },
    get end() {
      return strToDate(self.date, 1);
    },
  }))
  .actions((self) => ({
    addWhitelist(id) {
      if (!self.collisionWhitelist) self.collisionWhitelist = [];
      self.collisionWhitelist.push(id);
      self.updatedAt = new Date();
      getRoot(self).resources.triggerCollisionRecalculation();
    },
    delete() {
      self.deleted = true;
      self.updatedAt = new Date();
      getRoot(self).resources.triggerCollisionRecalculation();
    },
    registerReferences() {
      //TODO
      self._resource.registerAbsence(self.id);
    },
  }));

export const ResourceMessage = types
  .model("ResourceMessage", {
    id: types.identifier,
    _resource: types.late(() => types.reference(Resource)),
    deleted: types.optional(types.boolean, false),
    updatedAt: types.optional(types.Date, () => new Date()),
    type: types.string,
    creationTime: types.optional(types.Date, () => new Date()),
    creator: types.string,
    message: types.string,
    attachment: types.maybeNull(types.string),
  })
  .views((self) => ({
    get resource() {
      if (isValidReference(() => self._resource)) return self._resource;
      return null;
    },
    get hasAttachment() {
      return self.attachment && self.attachment.length > 0;
    },
  }))
  .actions((self) => ({
    delete() {
      self.deleted = true;
      self.updatedAt = new Date();
    },
    registerReferences() {
      //TODO
      self._resource.registerMessage(self.id);
    },
  }));

export const Deployment = types
  .model("Deployment", {
    id: types.identifier,
    _resource: types.late(() => types.reference(Resource)),
    _job: types.late(() => types.safeReference(ProjectJob)),
    _project: types.late(() => types.reference(Project)),
    _resGroup: types.maybeNull(types.late(() => types.safeReference(ResGroup))),
    deleted: types.optional(types.boolean, false),
    collisionWhitelist: types.maybeNull(types.array(types.string)),
    updatedAt: types.optional(types.Date, () => new Date()),
    additional: types.optional(types.string, ""),
    status: types.optional(
      types.enumeration(["UNKNOWN", "PRESENT", "MISSING"]),
      "PRESENT"
    ),
    start: types.optional(types.Date, () => new Date()),
    end: types.optional(types.Date, () => new Date()),
    projectName: types.optional(types.string, ""),
    related_record: types.maybeNull(types.optional(types.string, ""))
  })
  .views((self) => ({
    get job() {
      if (isValidReference(() => self._job)) return self._job;
      return {
        id: false,
        name: "?",
        start: self.start,
        end: self.end,
        deleted: false,
        process: {
          name: "?",
          deleted: false,
          project: { id: false, name: self.projectName, deleted: false },
          _project: { id: false, name: self.projectName, deleted: false },
        },
        project: { id: false, name: self.projectName, deleted: false },
        _process: {
          name: "?",
          deleted: false,
          project: { id: false, name: self.projectName, deleted: false },
          _project: { id: false, name: self.projectName, deleted: false },
        },
        _project: { id: false, name: self.projectName, deleted: false },
      };
    },
    get project() {
      if (isValidReference(() => self._project)) return self._project;
      return {
        id: false,
        name: self.projectName,
      };
    },
    get resource() {
      if (isValidReference(() => self._resource)) return self._resource;
      return null;
    },
  }))
  .actions((self) => ({
    setAdditional(text) {
      if (self.additional !== text) {
        self.additional = text;
        self.updatedAt = new Date();
      }
    },
    setRelatedRecord(uuid) {
      if (self.related_record !== uuid) {
        self.related_record = uuid;
        self.updatedAt = new Date();
      }
    },
    addWhitelist(id) {
      if (!self.collisionWhitelist) self.collisionWhitelist = [];
      self.collisionWhitelist.push(id);
      self.updatedAt = new Date();
      getRoot(self).resources.triggerCollisionRecalculation();
    },
    registerReferences() {
      //TODO
      try {
        self._job.registerDeployment(self.id);
      } catch (e) {}
      self._resource.registerDeployment(self.id);
      try {
        self._resGroup.registerDeployment(self.id);
      } catch (e) {}
    },
    delete() {
      self.deleted = true;
      self.updatedAt = new Date();
      getRoot(self).resources.triggerCollisionRecalculation();
    },
  }));

export const Order = types
  .model("Order", {
    id: types.identifier,
    _job: types.late(() => types.reference(ProjectJob)),
    _project: types.late(() => types.reference(Project)),
    deleted: types.optional(types.boolean, false),
    _material: types.maybeNull(types.late(() => types.reference(Material))),
    _supplier: types.maybeNull(types.late(() => types.reference(Supplier))),
    amount: types.number,
    area: types.number,
    thickness: types.number,
    updatedAt: types.optional(types.Date, () => new Date()),
  })
  .views((self) => ({
    oldFormat() {
      const q = JSON.parse(JSON.stringify(getSnapshot(self)));
      q.supplier = q._supplier;
      q.job = q._job;
      q.material = q._material;

      const formatProperty = (value) => {
        if (value === null) {
          return 0;
        } else if (typeof value === "string") {
          value = value.replace(",", ".");
        }
        value = parseFloat(value);
        return Number.isInteger(value) ? value : value.toFixed(2);
      };


      q.thickness = formatProperty(q.thickness);
      q.area = formatProperty(q.area);

      return pick(q, [
        "id",
        "job",
        "supplier",
        "amount",
        "material",
        "area",
        "thickness",
        "deleted",
        "updatedAt",
      ]);
    },
    get job() {
      if (isValidReference(() => self._job)) return self._job;
      return null;
    },
    get project() {
      if (isValidReference(() => self._project)) return self._project;
      return null;
    },
    get material() {
      if (isValidReference(() => self._material)) return self._material;
      return { name: "?" };
    },
    get supplier() {
      if (isValidReference(() => self._supplier)) return self._supplier;
      return { name: "?", safeShortname: "?" };
    },
  }))
  .actions((self) => ({
    registerReferences() {
      //TODO
      self._job.registerOrder(self.id);
    },
  }));

export const Disposition = types
  .model("Disposition", {
    id: types.identifier,
    _job: types.late(() => types.reference(ProjectJob)),
    _project: types.late(() => types.reference(Project)),
    deleted: types.optional(types.boolean, false),
    _supplier: types.maybeNull(types.late(() => types.reference(Supplier))),
    _type: types.maybeNull(types.late(() => types.reference(TruckType))),
    amount: types.number,
    comment: types.maybeNull(types.string),
    updatedAt: types.optional(types.Date, () => new Date()),
  })
  .views((self) => ({
    oldFormat() {
      const q = JSON.parse(JSON.stringify(getSnapshot(self)));
      q.supplier = q._supplier;
      q.job = q._job;
      q.type = q._type;
      return pick(q, [
        "id",
        "job",
        "supplier",
        "type",
        "amount",
        "comment",
        "deleted",
        "updatedAt",
      ]);
    },
    get job() {
      if (isValidReference(() => self._job)) return self._job;
      return null;
    },
    get project() {
      if (isValidReference(() => self._project)) return self._project;
      return null;
    },
    get type() {
      if (isValidReference(() => self._type)) return self._type;
      return { name: "?" };
    },
    get supplier() {
      if (isValidReference(() => self._supplier)) return self._supplier;
      return { name: "?", safeShortname: "?" };
    },
  }))
  .actions((self) => ({
    registerReferences() {
      //TODO
      self._job.registerDisposition(self.id);
    },
  }));

const OrderStore = types
  .model("OrderStore", {
    orders: types.map(Order),
    disposition: types.map(Disposition),
    absences: types.map(Absence),
    deployments: types.map(Deployment),
    resourceMessages: types.map(ResourceMessage),
    positions: types.map(types.late(() => Position)),
  })
  .views((self) => ({}))
  .actions((self) => ({
    getMPLinesBetween(v) {
      const lines = {};
      for (let order of self.orders.values()) {
        if (
          order.deleted ||
          order.amount < 1 ||
          !isValidReference(() => order._job) ||
          order._job.deleted ||
          order._job.start > v.end ||
          order._job.end < v.start ||
          !isValidReference(() => order._supplier) ||
          order._supplier.deleted ||
          !isValidReference(() => order._project) ||
          order._project.deleted ||
          !isValidReference(() => order._job._process) ||
          order._job._process.deleted
        ) {
          continue;
        }

        if (!(order._supplier.id in lines)) {
          lines[order._supplier.id] = {
            name: order._supplier.name,
            orders: [],
            id: order._supplier.id,
          };
        }
        lines[order._supplier.id].orders.push(order);
      }
      const lArray = Object.values(lines);
      lArray.sort((a, b) => a.name.localeCompare(b.name));
      return lArray;
    },
    setOrders(tmp) {
      applySnapshot(self.orders, tmp);
    },
    setDisposition(tmp) {
      applySnapshot(self.disposition, tmp);
    },
    update(collection, data, registerRefs) {
      for (let d of data) {
        if (collection.has(d.id)) {
          const originalData = collection.get(d.id);
          if (originalData.updatedAt >= new Date(d.updatedAt)) continue;
          d = Object.assign(
            JSON.parse(JSON.stringify(getSnapshot(originalData))),
            d
          );
        }
        try {
          collection.set(d.id, d);
          if (registerRefs) collection.get(d.id).registerReferences();
        } catch (e) {
          console.log(e);
        }
      }
    },
    collectChanges(lastSave) {
      const collections = [
        "orders",
        "disposition",
        "positions",
        "absences",
        "deployments",
        "resourceMessages",
      ];
      const out = {};
      for (let collectionName of collections) {
        const collection = self[collectionName];
        for (let data of collection.values()) {
          if (data.updatedAt < lastSave) continue;
          if (!(collectionName in out)) out[collectionName] = [];
          out[collectionName].push(
            omitBy(
              JSON.parse(JSON.stringify(getSnapshot(data))),
              (value, key) => key.startsWith("$")
            )
          );
        }
      }
      return out;
    },
    updateOrders(data) {
      self.update(self.orders, data, true);
    },
    updateResourceMessages(data) {
      self.update(self.resourceMessages, data, true);
    },
    updateDisposition(data) {
      self.update(self.disposition, data, true);
    },
    updatePositions(data) {
      self.update(self.positions, data, true);
    },
    updateAbsences(data) {
      self.update(self.absences, data, true);
      getRoot(self).resources.triggerCollisionRecalculation();
    },
    updateDeployments(data) {
      self.update(self.deployments, data, true);
      getRoot(self).resources.triggerCollisionRecalculation();
    },
  }));

export default OrderStore;
