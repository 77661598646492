import React from "react";
import ProjectResourceLine from "./projectResourceLine";
import ProjectGroupLine from "./projectGroupLine";

const ProjectResourceLines = ({ project, classSelection, xwidth, pstate }) => {
	return (
		<React.Fragment>
			{project.resources
				.filter(
					x =>
						classSelection.includes("ALL") ||
						(classSelection.includes("GROUPS") && x.isGroup) ||
						(!x.isGroup && classSelection.includes(x.resource.resclass.id))
				)
				.map(x => x.isGroup ? (
					<ProjectGroupLine xwidth={xwidth} pstate={pstate} data={x} key={x.resource.id} />
				) : (
					<ProjectResourceLine xwidth={xwidth} pstate={pstate} data={x} key={x.resource.id} />
				))}
		</React.Fragment>
	);
};

export default ProjectResourceLines;
