import React, { createRef } from "react";
import {
	Popup,
	Button,
	Input
} from "semantic-ui-react";

class RequestComment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			text: "",
			isOpen: false
		};
		this.handleClose = this.handleClose.bind(this);
		this.handleOpen = this.handleOpen.bind(this);
		this.nameInput = createRef();
	}

	save() {
		try {
			this.props.onSave(
				this.state.text
			);
			this.setState({
				isOpen: false
			});
		} catch (e) {
			console.log(e);
		}
	}

	componentDidUpdate(prevProps, prevState){
		if (!prevState.isOpen && this.state.isOpen){
			try{
			this.nameInput.current.focus();
		} catch (e){}
		}
	}

	updateText(text) {
		this.setState({text});
	}

	handleOpen() {
		this.setState({ isOpen: true, text: this.props.text });
		
	}

	handleClose() {
		this.setState({ isOpen: false });
	}

	render() {
		return (
			<Popup
				className="requestcomment"
				wide
				trigger={this.props.trigger}
				on="click"
				context={"ctxref" in this.props ? this.props.ctxref : null}
				open={this.state.isOpen}
				onClose={this.handleClose}
				onOpen={this.handleOpen}
				position={
					"ctxref" in this.props ? "right center" : "top right"
				}
			>
				<Popup.Content>
					<Input
						ref={this.nameInput} 
						value={this.state.text}
						onChange={(e, { value }) =>
							this.updateText(value)
						}
					/>
					<Button
						
						positive
						icon="check"
						onClick={() => this.save()}
					/>
				</Popup.Content>
			</Popup>
		);
	}
}

export default RequestComment;
