import React from "react";
import { observer, inject } from "mobx-react";
import {withTranslation} from "react-i18next";
import { Input, Button } from "semantic-ui-react";

class FilterInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: this.props.app.ui.filterString
		};
	}

	icons = {
		dateAscending: "sort content ascending",
		dateDescending: "sort content descending",
		numberAscending: "sort numeric ascending",
		numberDescending: "sort numeric descending",
		nameDescending: "sort alphabet descending",
		nameAscending: "sort alphabet ascending",
		fixedDescending: "lock",
		fixedAscending: "lock open"
	};

	next = {
		dateAscending: "dateDescending",
		dateDescending: "nameAscending",
		numberAscending: "numberDescending",
		numberDescending: "fixedDescending",
		fixedDescending: "fixedAscending",
		fixedAscending: "dateAscending",
		nameDescending: "numberAscending",
		nameAscending: "nameDescending"
	};
	
	updateValue(value) {
		this.setState({ value: value });
	}

	reallyUpdate(){
		this.props.app.ui.setFilterString(this.state.value);
	}

	onKeyPress(e){
		if(e.key === 'Enter'){
            this.reallyUpdate();
        }
	}

	render() {
		return (
			<React.Fragment><Input
				style={{
					padding: 5,
					flex: 1
				}}
				ref={input => input && input.focus()}
				size="mini"
				id="filterInput"
				action={{ icon: 'search', onClick: this.reallyUpdate.bind(this) }}
				autoFocus={true}
				name="filterInput"
				key="filterInput"
				onKeyPress={
					this.onKeyPress.bind(this)
				}
				placeholder={this.props.t("filter")}
				value={this.state.value}
				onChange={e => this.updateValue(e.target.value)}
			/>
			<Button onClick={() => this.props.app.ui.setSortMethod(this.next[this.props.app.ui.sortMethod])} icon={this.icons[this.props.app.ui.sortMethod]} size="small" /></React.Fragment>
		);
	}
}

export default withTranslation()(inject("app")(observer(FilterInput)));
