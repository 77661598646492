import React from "react";
import { observer, inject } from "mobx-react";
import moment from "moment";
import { strToDate } from "../../../helpers/calendar";
import { withTranslation } from "react-i18next";
import TextInterval from "./textInterval";
import ResourceBox from "./resourceBox";
import { getType } from "mobx-state-tree";

class ResourceLineBoxes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	static getX(props, date) {
		return (
			((date.getTime() - props.app.ui.view.start.getTime()) /
				props.app.ui.calDuration) *
			props.xwidth
		);
	}

	render() {
		const r = this.props.resource;
		console.time("resline "+r.name);
		const days = r.getDeployments(this.props.app.ui.view, true, false, true);
		const lineHeight = this.props.height;
		const padding = 0;
		const boxHeight = lineHeight - 2 * padding;

		const dayList = Object.keys(days);
		dayList.sort();
		const intervals = [];
		
		const boxes = dayList.map((date) => {
			/* Creates a list of consecutive day intervals, 
			by extending the interval if the end of the last interval is the previous day. 
			If not create a new interval. 
			requires that the days are iterated in an ordered way. */
			const projects = {};
			const processes = new Set();
			const groups = [];
			let abs = false;
			let greyDay = false;
			if (!Array.isArray(days[date]) && "greyDay" in days[date]) {
				greyDay = true;
			}
			let isNight = false;
			let hasAbsence = false;
			for (let deployment of greyDay ? [] : days[date]) {
				if (getType(deployment).name === "Absence") {
					hasAbsence = true;
					projects[deployment.type.id] = deployment.type.name;
					abs = {
						name: deployment.type.name,
						color: deployment.type.color,
					};
				} else {
					if (deployment._resGroup) {
						groups.push(deployment._resGroup.id);
					}
					isNight = isNight || deployment.job.isNight;
					projects[deployment.job.project.id] = deployment.job.project.name;
					if(deployment.job.process){
						processes.add(deployment.job.process.id);
					}
				}
			}
			const prKeys = Object.keys(projects);
			const ptext = greyDay
				? Object.values(days[date].absences).join(", ")
				: prKeys.length === 1
				? projects[prKeys[0]]
				: abs !== false
				? abs.name
				: this.props.t("resources.multipleProjects");

			if (
				intervals.length &&
				ptext === intervals[intervals.length - 1][2] &&
				Math.round(
					moment(strToDate(date)).diff(
						moment(strToDate(intervals[intervals.length - 1][1])),
						"days",
						true
					)
				) === 1
			) {
				intervals[intervals.length - 1][1] = date;
			} else {
				intervals.push([date, date, ptext]);
			}

			const hovered =
				this.props.app.ui.mouseOver.day === date &&
				(this.props.app.ui.mouseOver.resource === r.id ||
					groups.includes(this.props.app.ui.mouseOver.resource));

			return (
				<ResourceBox
					className={(greyDay ? "greyDay" : "") + (isNight ? " night" : "")}
					key={"r" + date}
					collision={prKeys.length > 1}
					viewStart={this.props.app.ui.view.start}
					calDuration={this.props.app.ui.calDuration}
					date={date}
					resid={r.id}
					draggable={
						this.props.app.ui.rights.has("PLAN_RESOURCES") &&
						(!hasAbsence || this.props.app.ui.rights.has("PLAN_VACATION"))
					}
					deployments={greyDay ? [] : days[date]}
					setDragMode={this.props.setDragMode}
					color={greyDay && days[date].colors.length ? days[date].colors[0] : (abs && prKeys.length === 1 ? abs.color : false)}
					hovered={hovered}
					xwidth={this.props.xwidth}
					boxHeight={boxHeight}
					onMouseEnter={
						greyDay
							? null
							: (e) =>
									this.props.app.ui.setMouseOver({
										projects: Object.keys(projects),
										processes: [...processes],
										resource: r.id,
										day: date,
									})
					}
					onMouseLeave={
						greyDay ? null : (e) => this.props.app.ui.resetMouseOver()
					}
				/>
			);
		});

		boxes.push(
			...intervals.map(([startT, endT, text]) => {
				return text === "" ? null : (
					<TextInterval
						hide={this.props.dragMode}
						key={"t" + startT}
						viewStart={this.props.app.ui.view.start}
						calDuration={this.props.app.ui.calDuration}
						xwidth={this.props.xwidth}
						boxHeight={boxHeight}
						start={startT}
						end={endT}
						text={text}
					/>
				);
			})
		);

		console.timeEnd("resline "+r.name);

		return <React.Fragment>{boxes}</React.Fragment>;
	}
}

export default inject("app")(withTranslation()(observer(ResourceLineBoxes)));
