import React from "react";
import { observer, inject } from "mobx-react";
import GroupLine from "./groupLine";
import ResourceLine from "./resourceLine";
import ResourceDetailLine from "./details/resourceDetailLine";

const ResourceLines = ({ app: { resources, ui }, width, edit }) => {

	const sortCalculation = (a, b) => {
		let sortFilter = ui.groupSortMethod;

		let rv = 0;
		const cx = a.resclass.name.localeCompare(b.resclass.name);

		if (cx === 0){
			switch (sortFilter) {
				case "dateAscending":
					rv = a.updatedAt < b.updatedAt ? -1 : a.updatedAt > b.updatedAt ? 1 : 0;
					break;
				case "dateDescending":
					rv = a.updatedAt < b.updatedAt ? 1 : a.updatedAt > b.updatedAt ? -1 : 0;
					break;
				case "nameAscending":
					rv = a.name.localeCompare(b.name);
					break;
				case "nameDescending":
					rv = b.name.localeCompare(a.name);
					break;
				default:
					break;
			}
		}else{
			rv = cx;
		}
		
		return rv;
	}

	return (
	<React.Fragment>
		{!(ui.currentClass.length === 1 && ui.currentClass[0] === "GROUPS")
			? Array.from(resources.getResourceLines())
					.sort((a, b) => {
						if (!a.resclass || !b.resclass) return 0;
						if (!a.resclass.human && b.resclass.human) return 1;
						if (a.resclass.human && !b.resclass.human) return -1;
						// const cx = a.resclass.name.localeCompare(b.resclass.name);
						// if (cx === 0) return a.name.localeCompare(b.name);
						const cx = sortCalculation(a, b);
						return cx;
					})
					.map((p) =>
						ui.mode === "BOARD" ? (
							<ResourceDetailLine key={p.id} xwidth={width} resource={p} />
						) : (
							<ResourceLine key={p.id} xwidth={width} resource={p} />
						)
					)
			: null}
		{ui.currentClass.includes("GROUPS") || ui.currentClass.includes("ALL")
			? resources
					.getGroupLines()
					.map((p) => <GroupLine key={p.id} xwidth={width} group={p} />)
			: null}
	</React.Fragment>
)};

export default inject("app")(observer(ResourceLines));
