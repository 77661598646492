import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import axios from "axios";
import {
  Tab,
  Button,
  Segment,
  Icon,
  Header,
  Divider,
  Feed,
  Form,
  Message
} from "semantic-ui-react";
import moment from "moment";

import ConfirmButton from "../controls/confirmButton";

class EditAttachments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      textField: "",
      loading: false,
      notSavedYet: false,
      saveMessage: false,
      dragVisible: false,
    };
    this.fileInputRef = React.createRef();
    this.fileChange = this.fileChange.bind(this);
    this.changeTextField = this.changeTextField.bind(this);
    this.changeSelect = this.changeSelect.bind(this);
    this.send = this.send.bind(this);
    this.allowDrag = this.allowDrag.bind(this);
    this.drop = this.drop.bind(this);
  }
  fileChange(e) {
    const fname = e.target.value;
    this.setState({
      selectedFile: fname ? fname.replace(/.*[/\\]/, "") : null,
      saveMessage: false,
    });
  }

  allowDrag(e) {
    e.dataTransfer.dropEffect = "copy";
    e.preventDefault();
  }

  drop(e) {
    e.preventDefault();
    this.fileInputRef.current.files = e.dataTransfer.files;
    const fname = this.fileInputRef.current.value;

    this.setState({
      dragVisible: false,
      selectedFile: fname ? fname.replace(/.*[/\\]/, "") : null,
      saveMessage: false,
    });
  }

  changeTextField(e, { value }) {
    this.setState({ textField: value, saveMessage: false });
  }

  changeSelect(e, { value }) {
    this.setState({ type: value, saveMessage: false });
  }

  send() {
    this.setState({ loading: true, saveMessage: false, notSavedYet: false });
    const formData = new FormData();
    const imagefile = this.fileInputRef.current;
    formData.append("project", this.props.edit.state.pid);
    formData.append(
      "file",
      imagefile.files.length ? imagefile.files[0] : false
    );
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/saveProjectAttachment",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("binfra_token"),
          },
        }
      )
      .then((response) => {
        this.save(this.state.textField, response.data);

        this.setState({
          loading: false,
          notSavedYet: false,
          selectedFile: null,
          textField: "",
          saveMessage: true,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false, notSavedYet: true, saveMessage: true });
      })
      .finally((x) => {
        setTimeout((x) => this.setState({ saveMessage: false }), 5000);
      });
  }

  save(description, data) {
    this.props.data.addAttachment(description, data);
  }

  render() {
    const stateAttachments = Object.values(
      this.props.edit.state.data.projectAttachments
    ).filter((x) => !x.deleted);

    let noAttachment = (
      <Segment placeholder size="small">
        <Header icon>
          <Icon name="times" />
          {this.props.t("edit.nodata.attachments.message")}
        </Header>
      </Segment>
    );

    let attachments = (
      <Feed size="large" className="messages">
        {stateAttachments.map((m) => {
          let type = {
            icon: "attach",
            text: "attachment",
          };

          return (
            <Feed.Event key={m.id}>
              <Feed.Label icon={type.icon} />
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User>{m.creator}</Feed.User>{" "}
                  {this.props.t("report.log.actions." + type.text + ".text")}
                  <Feed.Date>{moment(m.creationTime).calendar()}</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text>
                  {m.description.length ? m.description : m.attachment}
                </Feed.Extra>
                <Feed.Meta>
                  <Feed.Like
                    as="a"
                    target="_blank"
                    onClick={(e) => {
                      if (this.props.app.ui.lastSave < m.updatedAt) {
                        this.props.app.ui.openMessage("edit.saveFirst");
                        e.preventDefault();
                      }
                    }}
                    href={
                      process.env.REACT_APP_SERVER_URL +
                      "/getProjectAttachment/" +
                      m.id +
                      "/" +
                      m.attachment
                    }
                  >
                    <Icon name="attach" />{" "}
                    {this.props.t("report.log.openAttachment")}
                  </Feed.Like>

                  <ConfirmButton
                    disabled={!this.props.edit.state.data.editable}
                    onClick={(e) => {
                      this.props.data.deleteAttachment(m.id);
                      this.props.edit.saveHistory();
                    }}
                    trigger={
                      <Feed.Like>
                        <Icon name="trash alternate" />{" "}
                        {this.props.t("report.log.deleteAttachment")}
                      </Feed.Like>
                    }
                  />
                </Feed.Meta>
              </Feed.Content>
            </Feed.Event>
          );
        })}
      </Feed>
    );

    return (
      <Tab.Pane
        loading={this.state.loading}
        onDragEnter={() => this.setState({ dragVisible: true })}
      >
        {this.props.edit.state.data.editable ? (
          <Fragment>
            <Form>
              <Message
                visible={this.state.saveMessage}
                icon={this.state.notSavedYet ? "warning sign" : "checkmark"}
                error={this.state.notSavedYet}
                success={!this.state.notSavedYet}
                header={
                  this.state.notSavedYet
                    ? this.props.t("report.log.message.warn.header")
                    : this.props.t("report.log.message.ok.header")
                }
                content={
                  this.state.notSavedYet
                    ? this.props.t("report.log.message.warn.text")
                    : this.props.t("report.log.message.ok.text")
                }
              />
              <Form.TextArea
                label={this.props.t("report.log.textarea.title")}
                placeholder={this.props.t("report.log.textarea.placeholder")}
                value={this.state.textField}
                onChange={this.changeTextField}
              />
              <div className="flexgroup">
                <Button
                  content={
                    this.state.selectedFile
                      ? this.state.selectedFile
                      : this.props.t("report.log.fileChoose")
                  }
                  positive={!!this.state.selectedFile}
                  labelPosition="left"
                  icon="attach"
                  onClick={() => this.fileInputRef.current.click()}
                />
                <input
                  ref={this.fileInputRef}
                  type="file"
                  hidden
                  onChange={this.fileChange}
                />
                <Button
                  content={this.props.t("report.log.send")}
                  labelPosition="left"
                  icon="send"
                  primary
                  onClick={this.send}
                />
              </div>
            </Form>
            <Divider />
          </Fragment>
        ) : null}
        {!stateAttachments.length ? noAttachment : attachments}
        <div
          style={{ display: this.state.dragVisible ? "flex" : "none" }}
          className="dropZone"
          onDragEnter={this.allowDrag}
          onDragOver={this.allowDrag}
          onDragLeave={() => this.setState({ dragVisible: false })}
          onDrop={this.drop}
        ><Icon name="attach" /></div>
      </Tab.Pane>
    );
  }
}

export default inject("app")(withTranslation()(observer(EditAttachments)));
