import React from "react";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import {
	Button,
	Menu,
	Modal,
	Icon,
	Form,
} from "semantic-ui-react";
import moment from "moment";
import { DateRangePicker } from "react-dates";

class BackesExport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			focused: false,
			start: moment().startOf("day"),
			end: moment().add(1,'M').endOf("day"),
		};
	}

	open() {
		this.setState({ open: true });
	}

	close() {
		this.setState({ open: false });
	}

	render() {
		return (
			<Modal
				centered={false}
				className="duravermeerModal"
				trigger={
					<Button icon="file alternate outline" onClick={(e) => this.open()} />
				}
				open={this.state.open}
				onClose={(e) => this.close()}
			>
				<React.Fragment>
					<Menu>
						<Menu.Item header>{this.props.t("exports.mixture")}</Menu.Item>

						<Menu.Menu position="right">
							<Menu.Item name="close it" onClick={(e) => this.close()}>
								<Icon name="close" />
							</Menu.Item>
						</Menu.Menu>
					</Menu>
					<Modal.Content>
						<Form as="div">
							<Form.Field>
								<label>{this.props.t("exports.start")+ ' -> ' +this.props.t("end")}</label>
								<DateRangePicker
  									startDate={this.state.start} // momentPropTypes.momentObj or null,
  									startDateId="xdpicker_range_start" // PropTypes.string.isRequired,
  									endDate={this.state.end} // momentPropTypes.momentObj or null,
  									endDateId="xdpicker_range_end" // PropTypes.string.isRequired,
  									onDatesChange={({ startDate, endDate }) => this.setState({ start: startDate, end: endDate })} // PropTypes.func.isRequired,
									focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
  									onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,

  									hideKeyboardShortcutsPanel
  									numberOfMonths={3}
  									isOutsideRange={(x) => false}
									required
								/>
							</Form.Field>
						</Form>
						<form
							className="hiddenForm"
							method="get"
							action="https://bpo-projekt.de/ressourcen/BackesMixtureExport.php"
							target="_blank"
						>
							<input
								type="hidden"
								name="start"
								value={this.state.start.format("YYYY-MM-DD")}
							/>
							<input
								type="hidden"
								name="end"
								value={this.state.end.format("YYYY-MM-DD")}
							/>
							<Button positive fluid>
								{this.props.t("exports.get")}
							</Button>
						</form>
					</Modal.Content>
				</React.Fragment>
			</Modal>
		);
	}
}

export default withTranslation()(inject("app")(observer(BackesExport)));
