import React from "react";
import { isValidReference } from "mobx-state-tree";
import ResourceLine from "./resourceLine";

class GroupLine extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState((x) => ({ open: !x.open }));
	}

	render() {
		//console.log(this.props);
		return (
			<React.Fragment>
				<ResourceLine
					key={this.props.group.id}
					xwidth={this.props.xwidth}
					resource={this.props.group}
					group={this.toggle}
				/>
				{!this.state.open
					? null
					: Array.from(this.props.group._members.values())
							.filter((x) => isValidReference(() => x) && !x.deleted)
							.sort((a, b) => {
								if (!a.resclass || !b.resclass) return 0;
								if (!a.resclass.human && b.resclass.human) return 1;
								if (a.resclass.human && !b.resclass.human) return -1;
								const cx = a.resclass.name.localeCompare(b.resclass.name);
								if (cx === 0) return a.name.localeCompare(b.name);
								return cx;
							})
							.map((p) => (
								<ResourceLine
									key={p.id}
									xwidth={this.props.xwidth}
									resource={p}
									group={true}
								/>
							))}
			</React.Fragment>
		);
	}
}

export default GroupLine;
