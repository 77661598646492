import React, { createRef } from "react";
import { isValidReference } from "mobx-state-tree";
import {
	Modal,
	Button,
	Menu,
	Icon,
	Input,
	Loader, Dropdown
} from "semantic-ui-react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import RequestComment from "./requestComment";
import { arrayFilter } from "../../../helpers/calendar.js";

/*

Ressource wird dann als disponiert angezeigt, wenn die Ressource auf allen Jobs disponiert ist
Ressource wird dann als nicht verfügbar angezeigt, wenn sie während eines der Jobs wo anders ist, ausgenommen einer der Jobs
Zielpunkt der Ressourcenkarte ist der erste der gewählten Jobs
Startpunkt der Ressourcenkarte ist vorherige disponierte Job der Ressource

*/

class RequirementChooser extends React.Component {
	constructor(props) {
		super(props);
		this.state = RequirementChooser.initialState;
	}

	static initialState = {
		filter: "",
		selected: {},
		selectedClass: "",
		processes: [],
		jobs: {},
		currentProcess: "",
		projectName: ""
	};

	componentDidUpdate(prevProps) {
		if (!this.props.open && prevProps.open) {
			this.setState(RequirementChooser.initialState);
		} else if (this.props.open && !prevProps.open) {
			setTimeout(
				x =>
					this.setState(
						RequirementChooser.load(
							this.props.app.projects.getJobs(this.props.selectedJobs),
							this.props.app.resources.classes
						)
					),
				5
			);
		}
	}

	static load(jobs, classes) {
		const selected = {};

		const jobsOut = {};
		let processes = {};
		let firstJob = new Set();
		let currentProcess = false;
		let projectName = "";

		for (let job of jobs) {
			if (
				job.deleted ||
				!isValidReference(() => job._process) ||
				job._process.deleted ||
				!isValidReference(() => job._project) ||
				job._project.deleted
			)
				continue;

			projectName = job._project.fullName;
			const processId = job._process.id;
			currentProcess = currentProcess || processId;
			if (!(processId in processes)) processes[processId] = job._process;
			if (!(processId in jobsOut)) jobsOut[processId] = [];
			jobsOut[processId].push(job); // necessary
			const localList = {};

			for (let req of job.$requests.values()) {
				if (
					!isValidReference(() => req) ||
					req.deleted ||
					!isValidReference(() => req._requirement) ||
					req._requirement.deleted
				)
					continue;
				if (!(req._requirement.id in localList))
					localList[req._requirement.id] = { count: 0, comment: req.comment };
				localList[req._requirement.id].count += req.count;
				if (localList[req._requirement.id].comment !== req.comment)
					req.comment = false;
			}

			if (!firstJob.has(processId)) {
				selected[processId] = localList;
			} else {
				selected[processId] = RequirementChooser.mergeSets(
					selected[processId],
					localList
				);
			}

			firstJob.add(processId);
		}

		return {
			isOpen: true,
			selected,
			processes: Object.values(processes),
			currentProcess,
			projectName,
			jobs: jobsOut //list of jobs that are not deleted
		};
	}

	static mergeSets(s1, s2) {
		const newSet = {};
		const k1 = Object.keys(s1);
		const orphan1 = k1.filter(x => !(x in s2));
		const orphan2 = Object.keys(s2).filter(x => !(x in s1));
		const overlap = k1.filter(x => x in s2);
		for (let key of orphan1) newSet[key] = { count: false, comment: false };
		for (let key of orphan2) newSet[key] = { count: false, comment: false };
		for (let key of overlap) {
			//console.log(s1[key], s2[key], s1, s2);
			newSet[key] = {
				count: s1[key].count === s2[key].count ? s1[key].count : false,
				comment: s1[key].comment === s2[key].comment ? s1[key].comment : false
			};
		}
		return newSet;
	}

	componentDidMount() {}

	changeFilter(filter) {
		this.setState({ filter });
	}

	setCurrentProcess(currentProcess) {
		this.setState({ currentProcess });
	}

	selectClass(cls) {
		this.setState(prevState => {
			return {
				selectedClass: prevState.selectedClass === "" ? cls : ""
			};
		});
	}

	setText(requirement, text) {
		this.setState(prevState => {
			const selected = JSON.parse(JSON.stringify(prevState.selected));

			if (!(prevState.currentProcess in selected)) return null;
			if (!(requirement in selected[prevState.currentProcess])) return null;
			selected[prevState.currentProcess][requirement].comment = text;
			return {
				selected
			};
		});
	}
	increment(requirement, add) {
		this.setState(prevState => {
			const selected = JSON.parse(JSON.stringify(prevState.selected));
			if (!(prevState.currentProcess in selected)) return null;
			if (!(requirement in selected[prevState.currentProcess])) return null;
			selected[prevState.currentProcess][requirement].count += add;
			if (selected[prevState.currentProcess][requirement].count < 1)
				delete selected[prevState.currentProcess][requirement];
			return {
				selected
			};
		});
	}

	toggle(e, requirement) {
		e.persist();

		if (!e.target.classList.contains("accept")) return false;

		this.setState(prevState => {
			const selected = JSON.parse(JSON.stringify(prevState.selected));
			//console.log(selected);
			if (!(prevState.currentProcess in selected)) return null;
			if (!(requirement in selected[prevState.currentProcess]))
				selected[prevState.currentProcess][requirement] = {
					count: 1,
					comment: ""
				};
			else delete selected[prevState.currentProcess][requirement];
			//console.log(selected);
			return {
				selected
			};
		});
	}

	render() {
		if (!this.props.open) return null;

		const filter = arrayFilter(this.state.filter);
		return (
			<Modal
				centered={false}
				className="requirementChooserModal"
				open={this.props.open}
				onClose={e => this.props.close()}
			>
				{this.state.processes.length === 0 ? (
					<Loader size="large" />
				) : (
					<React.Fragment>
					
			
						<Menu className="chooserTopMenu grey">
							<Dropdown item className="resChoosDrop" text={this.props.t("requirementChooser.heading")}>
								<Dropdown.Menu className="blue">
									{this.props.app.ui.rights.has("PLAN_RESOURCES") ? (<Dropdown.Item 
										onClick={_ => this.props.app.ui.setRequirementMode(false)}
									>
										{this.props.t("resourceChooser.heading")}
									</Dropdown.Item>): null }
								</Dropdown.Menu>
							</Dropdown>
							<Menu.Item>
								<Input
									className="icon"
									icon="search"
									placeholder={this.props.t("filter")}
									value={this.state.filter}
									onChange={(e, { value }) => this.changeFilter(value)}
								/>
							</Menu.Item>

							<Menu.Menu position="right">
								<Menu.Item name="close it" onClick={e => this.props.close()}>
									<Icon name="close" /> {this.props.t("cancel")}
								</Menu.Item>
								<Menu.Item
									name="save it"
									onClick={e =>
										this.props.save(
											this.state.jobs,
											this.state.selected,
											this.state.initiallySelected
										)
									}
								>
									<Icon name="check" /> {this.props.t("ok")}
								</Menu.Item>
							</Menu.Menu>
						</Menu>
						<div className="outerProjectName">{this.state.projectName}</div>
						<Modal.Content>
							{this.state.processes.length > 1 ? (
								<Menu secondary className="processChooserMenu">
									{this.state.processes.map(x => (
										<Menu.Item
											name={x.id}
											key={x.id}
											active={x.id === this.state.currentProcess}
											onClick={_ => this.setCurrentProcess(x.id)}
										>
											<i
												className={
													x.template && x.template.icon
														? " picon picon-" + x.template.icon
														: ""
												}
											/>{" "}
											{x.name}
										</Menu.Item>
									))}
								</Menu>
							) : null}
							<div className="requirementChooserWrapper">
								{this.props.app.resources.reqList().map(c => {
									if (
										this.state.selectedClass !== "" &&
										this.state.selectedClass !== c.resClass.id
									)
										return null;
									let rescomps = c.res
										.map(r => {
											if (!filter([r.name,c.resClass.name ? c.resClass.name : ""])) return null;
											const reqdata =
												r.id in this.state.selected[this.state.currentProcess]
													? this.state.selected[this.state.currentProcess][r.id]
													: false;
											const contextRef = createRef();
											return (
												<div
													key={r.id}
													className={"resc_res" + (reqdata ? " xmarked" : "")}
													onClick={e => this.toggle(e, r.id)}
												>
													<div
														className="resc_image reqdata accept"
														ref={contextRef}
														style={{
															backgroundImage: "url(" + r.imageUrl + ")"
														}}
													>
														{reqdata ? (
															<React.Fragment>
																<div className="reqdata_count">
																	{reqdata.count ? reqdata.count : "?"}
																</div>
																<div className="reqdata_buttons">
																	<div
																		className="reqdata_icon"
																		onClick={_ => this.increment(r.id, 1)}
																	>
																		<Icon name="plus" />
																	</div>
																	<RequestComment
																		ctxref={contextRef}
																		onSave={text => this.setText(r.id, text)}
																		text={
																			reqdata.comment === false
																				? "?"
																				: reqdata.comment &&
																				  reqdata.comment.length
																				? reqdata.comment
																				: ""
																		}
																		trigger={
																			<div className="reqdata_icon">
																				<Icon
																					name={
																						reqdata.comment === false ||
																						(reqdata.comment &&
																							reqdata.comment.length)
																							? "comment alternate outline"
																							: "comment outline"
																					}
																				/>
																			</div>
																		}
																	/>
																	<div
																		className="reqdata_icon"
																		onClick={_ => this.increment(r.id, -1)}
																	>
																		<Icon name="minus" />
																	</div>
																</div>
															</React.Fragment>
														) : null}
													</div>
													<div className="resc_name">{r.name}</div>
												</div>
											);
										})
										.filter(x => x);

									if (!rescomps.length) {
										return null;
									}

									return (
										<div
											key={c.resClass.id}
											className={
												"resc_classwrapper"
											}
										>
											{c.resClass.id ? (<div className="resc_classname">
												<Button
													color={
														this.state.selectedClass === c.resClass.id
															? "grey"
															: null
													}
													onClick={() => this.selectClass(c.resClass.id)}
													icon
													labelPosition="left"
												>
													{c.resClass.name}
													<Icon name="filter" />
												</Button>
											</div>) : null }
											<div className="resc_wrapper">{rescomps}</div>
										</div>
									);
								})}
							</div>
						</Modal.Content>
					</React.Fragment>
				)}
			</Modal>
		);
	}
}

export default withTranslation()(inject("app")(observer(RequirementChooser)));
