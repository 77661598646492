import React from "react";
import { observer, inject } from "mobx-react";
import MixingPlantLine from "./mixingPlantLine";

const MixingPlantLines = (props) => { 
//console.log("MLI renders");
	return (
	<React.Fragment>
		{props.app.orders.getMPLinesBetween(props.app.ui.paddedView).map(x => (
			<MixingPlantLine key={x.id} data={x} xwidth={props.width}  />
		))}
	</React.Fragment>
);}

export default inject("app")(observer(MixingPlantLines));
