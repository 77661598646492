import {
	types,
	isValidReference,
	getSnapshot,
} from "mobx-state-tree";
import { ResClass } from "./resources";
import { ProcessTemplate} from "./basedata";
import omitBy from "lodash.omitby";

export const Question = types
	.model("Question", {
		id: types.identifier,
		_questionary: types.safeReference(types.late(() => Questionary)),
		type: types.enumeration(["TEXT","TIME","TIMESPAN","NUMBER","SELECT", "SIGNATURE"]),
		interfaceType: types.enumeration(["NONE","WH_PAUSE","WH_WORK","WH_STANDBY"]),
		title: types.string,
		properties: types.frozen(),
		focus: types.enumeration(["PROJECT","DAY"]),
		reference: types.enumeration(["ONCE","RESOURCE"]),
		_resclasses: types.array(types.safeReference(ResClass)),
		ordering: types.number,
		visibility: types.enumeration(["PUBLIC","PRIVATE"]),
		_supervisors: types.array(types.safeReference(ResClass)),
		deleted: types.optional(types.boolean, false),
		updatedAt: types.optional(types.Date, () => new Date()),
	})
	.views((self) => ({
		get questionary(){
			if (!isValidReference(() => self._questionary))
				return null;
			return self._questionary;
		}
	}))
	.actions((self) => ({
		registerReferences() {
			try {
				self._questionary.registerQuestion(self.id);
			} catch (e) {}
		},
		delete() {
      self.deleted = true;
      self.updatedAt = new Date();
    },
	}));

export const UserGroup = types
	.model("UserGroup", {
		id: types.identifier,
		deleted: types.optional(types.boolean, false),
		name: types.string,
		updatedAt: types.optional(types.Date, () => new Date())
	})
	.views(self => ({}))
	.actions(self => ({}));

export const Questionary = types
	.model("Questionary", {
		id: types.identifier,
		name: types.string,
		ordering: types.number,
		_groups: types.array(types.safeReference(UserGroup)),
		_processTemplates: types.array(types.safeReference(ProcessTemplate)),
		_resClasses: types.array(types.safeReference(ResClass)),
		$questions: types.map(types.reference(Question)),
		deleted: types.optional(types.boolean, false),
		updatedAt: types.optional(types.Date, () => new Date()),
	})
	.views((self) => ({}))
	.actions((self) => ({
		registerQuestion(id){
			self.$questions.set(id, id);
		},
		setOrdering(id){
			if (self.ordering !== id){
				self.ordering = id;
				self.updatedAt = new Date();
			}
		},
		delete() {
      self.deleted = true;
      self.updatedAt = new Date();
    },
	}));



export const QuestionStore = types
	.model("QuestionStore", {
		questions: types.map(Question),
		questionaries: types.map(Questionary),
		usergroups: types.map(UserGroup)
	})
	.views((self) => ({}))
	.actions((self) => ({
		update(collection, data, registerRefs) {
			for (let d of data) {
				if (collection.has(d.id)) {
					const originalData = collection.get(d.id);
					if (originalData.updatedAt >= new Date(d.updatedAt)) continue;
					d = Object.assign(
						JSON.parse(JSON.stringify(getSnapshot(originalData))),
						d
					);
				}
				try {
					collection.set(d.id, d);
					if (registerRefs) collection.get(d.id).registerReferences();
				} catch (e) {
					console.log(e);
				}
			}
		},
		collectChanges(lastSave) {
			const collections = ["questions", "questionaries"];
			const out = {};
			for (let collectionName of collections) {
				const collection = self[collectionName];
				for (let data of collection.values()) {
					if (data.updatedAt < lastSave) continue;
					if (!(collectionName in out)) out[collectionName] = [];
					out[collectionName].push(
						omitBy(
							JSON.parse(JSON.stringify(getSnapshot(data))),
							(value, key) => key.startsWith("$")
						)
					);
				}
			}
			return out;
		},
		updateQuestionaries(data) {
			self.update(self.questionaries, data, false);
		},
		updateUsergroups(data) {
			self.update(self.usergroups, data, false);
		},
		updateQuestions(data) {
			self.update(self.questions, data, true);
		},
	}));

export default QuestionStore;
