import React from "react";
import { inject } from "mobx-react";
import { strToDate } from "../../../helpers/calendar";
import DraggableCore from "react-draggable";

class ResourceBox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pos: 0
		};
	}

	static getX(start, calDuration, xwidth, date) {
		return ((date.getTime() - start.getTime()) / calDuration) * xwidth;
	}

	onStart() {
		this.props.setDragMode(true);
	}

	onStop(x) {
		const y = x.node.getBoundingClientRect();
		let last = false;
		let prelast = false;
		for (let line of document.getElementsByClassName("resdroptarget")){
			prelast = last;
			last = [line.dataset.resource, line.getBoundingClientRect().y];
			if (last[1] >= y.y)
				break;
		}
		let target;
		if (prelast){
			const targetX = Math.abs(y.y-last[1]) < Math.abs(y.y-prelast[1]) ? last : prelast;
			//console.log(Math.abs(y.y-targetX[1]) , y.height);
			if (Math.abs(y.y-targetX[1]) > y.height)
				target = false;
			else
				target = targetX[0];
		} else {
			if (Math.abs(y.y-last[1]) > y.height)
				target = false;
			else
				target = last[0];
		}
		if (target && target !== this.props.resid){
			//console.log("MOVE");
			this.props.app.resources.handleVerticalMove(this.props.deployments,target,this.props.date);
		}
		if (Math.abs(x) > 1)
			this.props.app.ui.resetWhiteClickData();
		this.props.setDragMode(false);
		this.setState({
			pos: 0
		});
	}

	onDrag(y) {
		this.setState({
			pos: y
		});
	}

	render() {
		/* Build blue resource boxes */
		let start = ResourceBox.getX(
			this.props.viewStart,
			this.props.calDuration,
			this.props.xwidth,
			strToDate(this.props.date)
		);

		const end = ResourceBox.getX(
			this.props.viewStart,
			this.props.calDuration,
			this.props.xwidth,
			strToDate(this.props.date, 1)
		);

		const style = {
			cursor: "pointer",
			position: "absolute",
			left: start + 1,
			width: end - start - 1,
			height: this.props.boxHeight - 1
		};
		if (this.props.color) style.background = this.props.color;
		return (
			<DraggableCore
				axis="none"
				onDrag={(x, y) => this.onDrag(y.y)}
				onStart={(x, y) => this.onStart(y)}
				onStop={(x, y) => this.onStop(y)}
				//defaultPosition={{ x: pos, y: 0 }}
				position={{ y: this.state.pos, x: 0 }}
				disabled={!this.props.draggable}
			>
				<div
					className={
						"standard_box" +
						(this.props.collision ? " collision" : "") +
						(this.props.hovered || this.state.pos !== 0 ? " hovered" : "") +
						(this.props.className ? " " + this.props.className : "")
					}
					style={style}
					onMouseEnter={this.props.onMouseEnter}
					onMouseLeave={this.props.onMouseLeave}
				/>
			</DraggableCore>
		);
	}
}

export default inject("app")(ResourceBox);
