import React from "react";
//import { Modal, Button, Menu, Icon, Input } from "semantic-ui-react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Map, Marker, TileLayer, Polyline } from "react-leaflet";
import { latLngBounds, AwesomeMarkers } from "leaflet";
import ResourceCarbonForm from "./resourceCarbonForm";
import { Table,	Form } from "semantic-ui-react"; 

class ResMapDetail extends React.Component {

	constructor(props) {
		super(props)  
		
		this.state = { 
			job: this.props.data.jobs[0],   
			rollerBandage: '',
			rollerSpeed: '',   
			rollerPerformance: 0
        }
	}

	componentWillMount(){  
		const [resclass, resid] = this.props.resourceKey.split("#");
		if(resclass === 'd16055b8-35e5-49d0-8a88-9b632a96a5fb'){
			if(this.props.data.jobs[0].roller_data?.roller_resources?.length){ 
				const roller_resources = this.props.data.jobs[0].roller_data?.roller_resources;
				const resData = roller_resources.filter( (curVal, index) => {
					return curVal.resource === resid;
				})
				if(resData.length) {
					this.setState({
						rollerBandage: resData[0].rollerBandage,
						rollerSpeed: resData[0].rollerSpeed,   
					});
					setTimeout(() => {
						this.calculateRollerperformance();
					}, 100);					
				}
			}			 
		}       
    }

	calculateRollerperformance(){
		const rollerPasses = 1;
		const rollerPerformanceCalc = Math.round( (0.9*parseFloat(this.state.rollerBandage)*parseFloat(this.state.rollerSpeed)*50)/parseFloat(rollerPasses) ); 
		this.setState({
			rollerPerformance: rollerPerformanceCalc
		});
	}	

	async handleRollerPerformance() { 
		if( this.state.rollerBandage && this.state.rollerSpeed ) {		
			this.calculateRollerperformance();
		} else {
			this.setState({
				rollerPerformance: 0 
			});
		}	 

		const updatedList = [];	
		// eslint-disable-next-line
		const [resclass, resid] = this.props.resourceKey.split("#");
		let has = 0;
		let rollerData = { ...this.state.job?.roller_data };
		if(rollerData?.roller_resources?.length){ 
			let roller_resource = rollerData?.roller_resources; 			
			// eslint-disable-next-line
			let resData = roller_resource.map((curVal) => {
				if(curVal.resource === resid ){
					curVal.rollerBandage = parseFloat(this.state.rollerBandage) || 0;
					curVal.rollerSpeed = parseFloat(this.state.rollerSpeed) || 0;
					has = 1;
				}
				return curVal;
			}); 
			if(!has){ 
				let roller_resources_temp = [ ...rollerData.roller_resources ];
				roller_resources_temp.push({
					resource: resid,
					rollerBandage: parseFloat(this.state.rollerBandage),
					rollerSpeed: parseFloat(this.state.rollerSpeed)
				}) 
				rollerData.roller_resources = roller_resources_temp;
				this.state.job.setRollerdata(rollerData) 
			}

		} else {
			rollerData.roller_resources = [];
			rollerData.roller_resources.push({
				resource: resid,
				rollerBandage: parseFloat(this.state.rollerBandage),
				rollerSpeed: parseFloat(this.state.rollerSpeed)
			}) 
			this.state.job.setRollerdata(rollerData)				 
		}			 
		updatedList.push([this.state.job, 0]);  
		this.props.app.projects.move(updatedList);			  
	}

	render() {  
		const positionIsValid = statePos => {
			return (
				statePos &&
				statePos !== null &&
				statePos.lat !== 0 &&
				statePos.lng !== 0 &&
				statePos.zoom !== 0
			);
		};

		const [resclass, resid] = this.props.resourceKey.split("#");
		const res = this.props.app.resources.get(resclass, resid);
		if (res === null) return null;

		const markers = [];
		let line = [];
		if (positionIsValid(res.lastLocation)) {
			markers.push({
				latlng: [res.lastLocation.lat, res.lastLocation.lng],
				zoom: res.lastLocation.zoom,
				key: "lastLocation",
				icon: AwesomeMarkers.icon({
					icon: "map marker alternate",
					markerColor: "darkblue"
				})
			});
		}
		if (positionIsValid(this.props.information.previousPosition)) {
			markers.push({
				latlng: [
					this.props.information.previousPosition.lat,
					this.props.information.previousPosition.lng
				],
				zoom: this.props.information.previousPosition.zoom,
				key: "previousPosition",
				icon: AwesomeMarkers.icon({
					icon: "flag outline",
					markerColor: "darkgreen"
				})
			});
			line.push([
				this.props.information.previousPosition.lat,
				this.props.information.previousPosition.lng
			]);
		}
		if (positionIsValid(this.props.position)) {
			markers.push({
				latlng: [this.props.position.lat, this.props.position.lng],
				zoom: this.props.position.zoom,
				key: "nextPosition",
				icon: AwesomeMarkers.icon({
					icon: "flag checkered",
					markerColor: "red"
				})
			});
			if (line.length > 0) {
				line.push([this.props.position.lat, this.props.position.lng]);
			}
		}

		for (let overlappingJob of this.props.information.overlappingJobs) {
			if (!positionIsValid(overlappingJob.safePosition)) continue;
			markers.push({
				latlng: [overlappingJob.safePosition.lat, overlappingJob.safePosition.lng],
				zoom: overlappingJob.safePosition.zoom,
				key: overlappingJob.id,
				icon: AwesomeMarkers.icon({
					icon: "lightning",
					markerColor: "orange",
					color: "black"
				})
			});
		}
		//console.log(markers);
		const mapSettings = {};
		if (markers.length > 1) {
			const bounds = latLngBounds(markers[0].latlng);
			for (let i = 0; i < markers.length; i++) {
				bounds.extend(markers[i].latlng);
			}
			if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
				mapSettings.center = markers[0].latlng;
				mapSettings.zoom = this.props.t("stdPos.zoom", 6);
			} else mapSettings.bounds = bounds.pad(0.3);
		} else if (markers.length === 1) {
			mapSettings.center = markers[0].latlng;
			mapSettings.zoom = this.props.t("stdPos.zoom", 6);
		} else {
			mapSettings.center = [
				parseFloat(this.props.t("stdPos.lat", "50.8")),
				parseFloat(this.props.t("stdPos.lng", "10.5"))
			];
			mapSettings.zoom = parseInt(this.props.t("stdPos.zoom", "6"));
		} 



		const info = Array.from(res.information);
		return (
			<div className="resMapDetail">
				{this.props.marker !== false ? (
					<div className="resMapMarker" style={{ left: this.props.marker }} />
				) : null}
				<div className="resMap">
					<Map {...mapSettings}>
						<TileLayer url="https://maps.bpo-asphalt.de/tile.php?z={z}&x={x}&y={y}&key=dboth&type=standard" />
						{markers.map(m => (
							<Marker icon={m.icon} key={m.key} position={m.latlng} />
						))}
						{line.length === 2 ? (
							<Polyline dashArray={6} color="#666" positions={line} />
						) : null}
					</Map>
				</div>
				<div>
					{info.length ? (
						<div className="details">
							{info.map(([question, answer]) => (
								<div key={question} className="popupLine">
									<div className="popupQuestion">{question}</div> 
									<div className="popupAnswer">{answer}</div>
								</div>
							))}
						</div>
					) : null} 

					{ this.props.resourceClass.name.toLowerCase() === 'walzen' && this.props.process === "Asphalt"  && this.props.app.ui.modules.has("ROLLER_CONCEPTS") && this.props.app.ui.rights.has("WRITE_ROLLER_MASTERDATA")? (
						<div className="roller-resources-calculator details"> 
							<h4>{this.props.t("edit.panes.rollerconcept")}</h4>
							<Table singleLine>
								<Table.Body> 								 					
									<Table.Row>
										<Table.Cell>
											{this.props.t("edit.roller_concept.labels.bandage_width")}:
										</Table.Cell>
										<Table.Cell> 
											<Form.Field className='roller-concept-NSPN-input'> 
												<Form.Input 
													placeholder=""  
													type="number" 
													min="0" 
													name="rollerBandage"
													value={this.state.rollerBandage}
													onChange={(event) => {  																	 
														this.setState({ rollerBandage: event.target.value })
														setTimeout( ()=> {
															this.handleRollerPerformance();
														}, 100)				 
													}}																      
													/>
													<div className="NSPN-unit">m</div> 
											</Form.Field>												
										</Table.Cell>																													
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{this.props.t("edit.roller_concept.labels.performance_speed")}:
										</Table.Cell>
										<Table.Cell> 
											<Form.Field className='roller-concept-NSPN-input'> 
												<Form.Input 
														placeholder=""  
														type="number" 
														min="0" 
														name="rollerSpeed"
														value={this.state.rollerSpeed}
														onChange={(event) => { 
															this.setState({ rollerSpeed: event.target.value })
															setTimeout( ()=> {
																this.handleRollerPerformance();
															}, 100)				 
														}}																      
														/>
													<div className="NSPN-unit">m/min</div>  
											</Form.Field>												
										</Table.Cell>
									</Table.Row> 

									{this.state.rollerPerformance > 0 ? (		
										<Table.Row>
											<Table.Cell>
												{this.props.t("edit.roller_concept.labels.roller_performance")}:
											</Table.Cell>
											<Table.Cell>
												{this.state.rollerPerformance.toLocaleString('en-DE')} m<sup>2</sup>/h
											</Table.Cell>											
										</Table.Row> 
									) : null }

								</Table.Body>
							</Table>
						</div>
					) : null }


					{ !this.props.resourceClass.human && this.props.app.ui.modules.has("CARBON_CONCEPTS") && this.props.app.ui.rights.has("WRITE_CARBON_MASTERDATA")? (
						
						<ResourceCarbonForm bpo={this.props.bpo} data={this.props} resourceClass={this.props.resourceClass} resourceKey={this.props.resourceKey}/>

					) : null }




				</div>
			</div>
		);
	}
}

export default inject("app")(withTranslation()(observer(ResMapDetail)));
