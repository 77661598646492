import React from "react";
import { observer, inject } from "mobx-react";
import ContainerDimensions from 'react-container-dimensions'

import Calendar from "../components/calendar/calendar";
import CalendarButtons from "../components/calendar/calendarButtons";

// Import Common Stylesheets


const CalPage = (props) => (
	<div id="calPage" className="calPage">
		{!props.app.ui.dayView ? <CalendarButtons insideCal={true} /> :null}
		<ContainerDimensions>
			<Calendar appCtx={props.appCtx} />
		</ContainerDimensions>
	</div>
);

export default inject("app")(observer(CalPage));
