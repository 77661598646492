import React from "react";

import LogoImg from "../../images/logo.png";



const Header = ({fullscreen, buttons }) => (
	<header className={"no-print"+(fullscreen?" fullscreen" : "")}>
		<img src={LogoImg} id="logo" alt="BPO Logo" />
		<div id="centerspace" />
		{
			buttons
		}
		
		
		<div id="redbar" />
	</header>
);

export default Header;
